import { useContext } from 'react';

import { getAuth, getIdTokenResult } from '@firebase/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { ACCOUNT_QUERY_KEY } from '@/hooks/account/useAccountQuery';
import { updateAccountSettings } from '@/services/api/account';
import { TUserAccount, TUserWorkspace } from '@/types/workspace';

type UserAccountUpdate = { workspaceId: string } & Pick<
  TUserWorkspace,
  'weeklyEmail'
>;
export default function useUpdateAccountSettings({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
} = {}) {
  const queryClient = useQueryClient();
  const auth = getAuth();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation<
    TUserAccount | undefined,
    Error & { message?: string },
    UserAccountUpdate
  >({
    mutationFn: async ({ weeklyEmail, workspaceId }: UserAccountUpdate) =>
      await updateAccountSettings({
        weeklyEmail: weeklyEmail ?? false,
        workspaceId,
      }),
    onSuccess: async () => {
      if (auth.currentUser) await getIdTokenResult(auth.currentUser, true);
      await queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY] });
      onSuccess?.();
    },
    onError: (error) => {
      onError?.(error);
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
