import { useCallback, useContext } from 'react';

import { deleteObject, getStorage, ref } from '@firebase/storage';
import { useMutation } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';

type Props = { refPath: string };

export default function useFirebaseDeleteFile() {
  const storage = getStorage();

  const deleteToFirebase = useCallback(
    async (refPath: string): Promise<void> => {
      const storageRef = ref(storage, refPath);
      await deleteObject(storageRef);
    },
    [storage],
  );

  const { addNotification } = useContext(NotificationsContext);
  return useMutation<void, Error, Props>({
    mutationFn: async ({ refPath }: Props): Promise<void> =>
      await deleteToFirebase(refPath),
    onError: (error: Error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
