import { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash';
import queryString from 'query-string';
import { Navigate, useNavigate } from 'react-router';

import { UserContext } from '@/contexts/UserContext';
import useAcceptInvitation from '@/hooks/account/useAcceptInvitation';
import { ACCOUNT_QUERY_KEY } from '@/hooks/account/useAccountQuery';
import useInvitationQuery from '@/hooks/account/useInvitationQuery';
import { TResponseError } from '@/types/generic';
import { ROUTES } from '@/types/routes';

import Loader from '@/components/Loader';

import InvitationExpiredError409 from '../ErrorScreen/400/InvitationlExpiredError400';
import { Error500 } from '../ErrorScreen/500';

export default function AcceptInvitationScreen() {
  const { tk: token = '' } = queryString.parse(location.search);
  const { mutate } = useAcceptInvitation();
  const { user, account, setWorkspaceId } = useContext(UserContext);
  const navigate = useNavigate();

  const [errorElement, setErrorElement] = useState<JSX.Element | undefined>(
    undefined,
  );
  const queryClient = useQueryClient();

  const { isLoading: checkInvitation, isError } = useInvitationQuery({
    enabled: !isNil(token),
    onError: (error: TResponseError) => {
      if (error.status === 400) {
        setErrorElement(<InvitationExpiredError409 />);
      } else {
        setErrorElement(<Error500 />);
      }
    },
  });
  const [isLoading, setIsLoading] = useState(checkInvitation);

  useEffect(() => {
    if (!checkInvitation && !isError && token) {
      mutate(token as string, {
        onSuccess: (data) => {
          const { newWorkspace, ...account } = data;
          setWorkspaceId(newWorkspace);
          queryClient.setQueryData([ACCOUNT_QUERY_KEY], account);
          queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY] });
          navigate(ROUTES.ROOT, { preventScrollReset: false, replace: true });
        },
        onError: () => {
          setIsLoading(false);
          setErrorElement(<Error500 />);
        },
      });
    } else if (isError) {
      setIsLoading(false);
    }
  }, [
    account,
    isError,
    checkInvitation,
    mutate,
    navigate,
    queryClient,
    token,
    user,
    setWorkspaceId,
  ]);

  return isLoading ? (
    <Loader />
  ) : errorElement ? (
    errorElement
  ) : (
    <Navigate to={ROUTES.ROOT} />
  );
}
