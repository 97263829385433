import Layout from '../components/Layout';
import TimelineProject from '../components/TimelineProject';
import TimelineDetails from '../components/TimelineProject/TimelineDetail';
import Header from '../components/common/Header';
import { TimelineProjectProvider } from '../context/TimelineProjectContext';

export default function Home() {
  return (
    <Layout title="Timeline">
      <TimelineProjectProvider>
        <Header />
        <TimelineProject />
        <TimelineDetails />
      </TimelineProjectProvider>
    </Layout>
  );
}
