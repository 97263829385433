import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { createAccessToken } from '@/services/api/workspace/workspace';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';

import { ACCOUNT_QUERY_KEY } from '../account/useAccountQuery';

export default function useCreateAccessToken({
  onSuccess,
  onError,
}: TSuccessErrorHook<{
  accessToken: string;
  id: string;
}>) {
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);

  return useMutation<{ accessToken: string; id: string }, Error, string>({
    mutationFn: async (workspaceId: string) => {
      return createAccessToken(workspaceId);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY] });
      onSuccess?.(data);
    },
    onError: (error) => {
      onError?.(error as unknown as TResponseError);
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
