import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';
import axios, { isAxiosError } from 'axios';

import { handleApiCall } from '@/services/api';
import { BaseResponse } from '@/types/base-responses';
import { TSuccessErrorHookWithParams } from '@/types/generic';

export const VALIDATE_TOKEN_KEY = 'validate-token';
export default function useValidatePublicToken({
  onSuccess,
  onError,
  uuid,
}: TSuccessErrorHookWithParams<
  {
    isValid: boolean;
    uuid: string;
  },
  {
    uuid: string;
  }
>) {
  const validateToken = useCallback(async (uuid: string) => {
    const {
      data: { data: validation },
    } = await axios.get<BaseResponse<{ id: string; url: string }>>(
      `${import.meta.env.VITE_API_URL}/public/validate-token/${uuid as string}`,
    );
    return {
      isValid: !!validation.id,
      uuid,
    };
  }, []);

  return useQuery({
    queryKey: [VALIDATE_TOKEN_KEY, uuid],
    queryFn: ({ queryKey: [_k, tk] }) =>
      handleApiCall(validateToken(tk), onSuccess, onError),
    retry: (failureCount, error) =>
      isAxiosError(error) && error.response?.data?.status === 403
        ? false
        : failureCount < 3,
  });
}
