import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { type Paddle, type PaddleEventData } from '@paddle/paddle-js';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { t } from 'i18next';

import { useProfileModal } from '@/contexts/ModalPreferenceContext';
import { UIContext } from '@/contexts/UIContext';
import { UserContext, useSubscription } from '@/contexts/UserContext';
import useGetPaymentMethod, {
  GET_PAYMENT_METHOD_KEY,
} from '@/hooks/paddle/useGetPaymentMethod';
import useGetPaymentUpdateTransaction, {
  GET_PAYMENT_UPDATE_TRANSACTION_KEY,
} from '@/hooks/paddle/useGetPaymentUpdateTransaction';
import usePaddle from '@/hooks/paddle/usePaddle';
import { titleCase } from '@/services/helpers';
import { SubscriptionStatusEnum } from '@/types/enums';
import { TPaymentInfo } from '@/types/invoices';

import Button from '@/components/Button';
import Chip from '@/components/Chip';
import Section from '@/components/Section';

import { ContentPages } from '..';
import ActionRow from './ActionRow';
import InvoiceList from './InvoiceList';
import styles from './styles.module.css';

export default function ModalBillingInfo() {
  const { theme } = useContext(UIContext);
  const { workspace } = useContext(UserContext);
  const {
    subscription,
    renewal,
    plan,
    isPastDue,
    isActive: activeSubscription,
  } = useSubscription();

  const nextTransaction = useMemo(() => {
    if (subscription?.nextTransaction) return subscription.nextTransaction;
  }, [subscription?.nextTransaction]);

  const { setSelectedPageId } = useProfileModal();
  const queryClient = useQueryClient();
  const paddleOnCheckoutComplete = useCallback(
    (event: PaddleEventData, paddleCheckout?: Paddle) => {
      setRequestPaymentUpdate(false);
      // TODO: continuare refactor
      queryClient.invalidateQueries({
        queryKey: [
          GET_PAYMENT_UPDATE_TRANSACTION_KEY,
          workspace?.id,
          subscription?.id,
        ],
      });
      if (event.data?.payment?.method_details) {
        const methodDetail = event.data.payment.method_details;
        const newPayemntInfo = {
          type: methodDetail.type,
          card: methodDetail.card && {
            expiryMonth: methodDetail.card.expiry_month,
            expiryYear: methodDetail.card.expiry_year,
            last4: methodDetail.card.last4,
            type: methodDetail.card.type,
          },
        };
        queryClient.setQueryData<TPaymentInfo>(
          [GET_PAYMENT_METHOD_KEY, workspace?.id, subscription?.id],
          newPayemntInfo,
        );
      }
      paddleCheckout?.Checkout?.close();
    },
    [queryClient, subscription?.id, workspace?.id],
  );

  const { paddle } = usePaddle({
    onCheckoutCompleted: paddleOnCheckoutComplete,
  });

  const description = useMemo(() => {
    switch (plan) {
      case 'free':
        return t('common:profileModal.billingInfo.planDescription.free');
      case 'pro':
        if (isPastDue) return `Your subscription is past due.`;
        else if (!activeSubscription)
          return t(
            'common:profileModal.billingInfo.planDescription.proWaiting',
            {
              billingCycle:
                subscription?.billingCycle === 'month' ? 'monthly' : 'annual',
            },
          );
        else if (!subscription?.scheduledAction)
          return t('common:profileModal.billingInfo.planDescription.pro', {
            price: nextTransaction?.total.toFixed(2) ?? '0',
            billingCycle:
              subscription?.billingCycle === 'month' ? 'monthly' : 'annual',
            renewalDate: renewal && format(renewal, 'MMMM do yyyy'),
            currency: nextTransaction?.currencyCode,
          });
        else
          return `Your subscription will be ${subscription.scheduledAction} on ${subscription.scheduledChangeAt && format(subscription.scheduledChangeAt, 'MMMM do yyyy')}`;
      default:
        return '';
    }
  }, [
    activeSubscription,
    isPastDue,
    nextTransaction?.currencyCode,
    nextTransaction?.total,
    plan,
    renewal,
    subscription?.billingCycle,
    subscription?.scheduledAction,
    subscription?.scheduledChangeAt,
  ]);

  const [requestPaymentUpdate, setRequestPaymentUpdate] =
    useState<boolean>(false);

  const { data, isLoading: _loading } = useGetPaymentUpdateTransaction({
    subscriptionId: subscription?.id ?? '',
    enabled: !!workspace?.id && activeSubscription && requestPaymentUpdate,
  });

  const { data: paymentMethod } = useGetPaymentMethod({
    subscriptionId: subscription?.id ?? '',
    enabled: !!workspace?.id && activeSubscription,
  });

  const openPaddleUpdatePayment = useCallback(
    (transactionId: string) =>
      paddle?.Checkout.open({
        transactionId,
        settings: {
          theme: theme === 'dark' ? 'dark' : 'light',
        },
      }),
    [paddle?.Checkout, theme],
  );

  const handleUpdatePaymentRequest = useCallback(() => {
    if (data?.id) openPaddleUpdatePayment(data.id);
    else setRequestPaymentUpdate(true);
  }, [data?.id, openPaddleUpdatePayment]);

  useEffect(() => {
    if (data?.id && requestPaymentUpdate) openPaddleUpdatePayment(data.id);
  }, [data?.id, openPaddleUpdatePayment, requestPaymentUpdate]);

  return (
    <Section title={t('common:profileModal.billingInfo.title')}>
      <div className={styles.container}>
        <div className={styles.actionSectionWrapper}>
          <ActionRow
            label={
              isPastDue ? (
                <div style={{ display: 'flex', gap: '8px' }}>
                  {`${titleCase(plan)} plan`}{' '}
                  <Chip size="medium" type="caution">
                    Past due
                  </Chip>
                </div>
              ) : (
                `${titleCase(plan)} plan`
              )
            }
            description={description}
            action={
              subscription?.status !== SubscriptionStatusEnum.pending ? (
                <Button
                  label={t(
                    'common:profileModal.billingInfo.actions.changePlan',
                  )}
                  size="medium"
                  variant="outline"
                  onClick={() => setSelectedPageId(ContentPages.PLANS)}
                />
              ) : undefined
            }
          />
        </div>
        {subscription?.id &&
          subscription?.status !== SubscriptionStatusEnum.pending && (
            <div className={styles.actionSectionWrapper}>
              <ActionRow
                label={t(
                  'common:profileModal.billingInfo.labels.paymentMethod',
                )}
                description={
                  <div className={styles.cardInfoContainer}>
                    {paymentMethod?.card ? (
                      <span>
                        {t('common:profileModal.billingInfo.cardValid', {
                          last4: paymentMethod.card.last4,
                          expiry: `${paymentMethod.card.expiryMonth}/${paymentMethod.card.expiryYear}`,
                        })}
                      </span>
                    ) : (
                      ''
                    )}
                    {isPastDue && (
                      <span className={styles.paymentError}>
                        We were unable to process your last payment. Please
                        update your billing information to avoid service
                        interruption.
                      </span>
                    )}
                  </div>
                }
                action={
                  <Button
                    label={t(
                      'common:profileModal.billingInfo.actions.updatePayment',
                    )}
                    size="medium"
                    variant="ghost"
                    onClick={handleUpdatePaymentRequest}
                  />
                }
              />
            </div>
          )}
        <Section
          title={t('common:profileModal.billingInfo.invoice.title')}
          className={styles.invoiceSection}
        >
          <InvoiceList />
        </Section>
      </div>
    </Section>
  );
}
