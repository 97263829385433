import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';

import { UserContext } from '@/contexts/UserContext';
import useDeleteAccountImage from '@/hooks/account/useDeleteAccountImage';
import useUpdateAccount from '@/hooks/account/useUpdateAccount';
import useUpdateAccountImage from '@/hooks/account/useUpdateAccountImage';
import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { STORAGE_BASE_PATH } from '@/types/enums';
import { TAttachmentDataUrls } from '@/types/generic';
import { TUserAccount } from '@/types/workspace';

import AvatarUploader from '@/components/AvatarUploader';
import Input from '@/components/Input';
import Section from '@/components/Section';

import AccountSecuritySection from './sections/AccountSecuritySection';
import DeleteAccountSection from './sections/DeleteAccountSection';
import styles from './styles.module.css';

type AccountForm = Omit<TUserAccount, 'workspaces' | 'image'> & {
  password?: string;
};

export default function ModalProfileContent() {
  const { account, workspaceId } = useContext(UserContext);
  const [attachments, setAttachments] = useState<TAttachmentDataUrls[]>(
    account?.image?.ref ? [account.image] : [],
  );

  const { mutate: mutateUpdateAccount, isPending: isUpdateAccountLoading } =
    useUpdateAccount({ sendSuccessNotification: false });

  const queryClint = useQueryClient();

  const updateResources = useCallback(() => {
    queryClint.invalidateQueries({
      queryKey: [RESOURCES_QUERY_KEY, workspaceId],
    });
  }, [queryClint, workspaceId]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<AccountForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const firstName = watch('firstName');
  const lastName = watch('lastName');

  const canSave = useMemo(() => {
    return (
      isValid &&
      isDirty &&
      (account?.firstName !== firstName || account?.lastName !== lastName)
    );
  }, [
    account?.firstName,
    account?.lastName,
    firstName,
    isDirty,
    isValid,
    lastName,
  ]);

  useEffect(() => {
    setAttachments(account?.image?.ref ? [account.image] : []);
  }, [account?.image]);

  const onSubmitFn = useCallback(
    async (data: AccountForm) => {
      mutateUpdateAccount(data, {
        onSuccess: () => {
          updateResources();
          reset(undefined, { keepValues: true });
        },
      });
    },
    [mutateUpdateAccount, reset, updateResources],
  );

  const { mutate: mutateUpdateAccountImage } = useUpdateAccountImage();
  const { mutate: mutateDeleteAccountImage } = useDeleteAccountImage();

  const onUploadSuccessFn = useCallback(
    (data: TAttachmentDataUrls) => {
      setAttachments([data]);
      mutateUpdateAccountImage(
        {
          filePath: data?.ref as string,
        },
        { onSuccess: updateResources },
      );
    },
    [mutateUpdateAccountImage, updateResources],
  );

  const onRemoveImageFn = useCallback(() => {
    setAttachments([]);
    mutateDeleteAccountImage(undefined, { onSuccess: updateResources });
  }, [mutateDeleteAccountImage, updateResources]);

  const onInputKeyEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter') return;
      (event.target as HTMLInputElement)?.blur();
    },
    [],
  );

  const onInputBlur = useCallback(() => {
    if (canSave) handleSubmit(onSubmitFn)();
  }, [canSave, handleSubmit, onSubmitFn]);

  return (
    <React.Fragment>
      <Section title="Profile picture">
        <div className={styles['image-container']}>
          <AvatarUploader
            onRemoveFileSuccess={onRemoveImageFn}
            basePath={STORAGE_BASE_PATH.ACCOUNTS}
            onChange={setAttachments}
            resourceName={account?.firstName + ' ' + account?.lastName}
            onUploadSuccess={onUploadSuccessFn}
            multiple={false}
            data={attachments}
          />
        </div>
      </Section>
      <Section title="Profile Info">
        <div className={styles.profileForm}>
          <div className={styles.inputWrapper}>
            <Controller
              rules={{
                maxLength: {
                  value: 180,
                  message: t('forms:profileSettings.firstName.maxLength'),
                },
                required: {
                  value: true,
                  message: t('forms:profileSettings.firstName.required'),
                },
              }}
              control={control}
              defaultValue={account?.firstName ?? ''}
              name="firstName"
              render={({ field: { value, name, onChange } }) => {
                return (
                  <Input
                    required
                    label={t('forms:profileSettings.firstName.label')}
                    value={value}
                    placeholder={t(
                      'forms:profileSettings.firstName.placeholder',
                    )}
                    autoComplete="given-name"
                    onChange={onChange}
                    disabled={isUpdateAccountLoading}
                    onKeyDown={onInputKeyEnter}
                    onBlur={onInputBlur}
                    name={name}
                    error={errors?.firstName?.message}
                  />
                );
              }}
            />
            <Controller
              rules={{
                maxLength: {
                  value: 180,
                  message: t('forms:profileSettings.lastName.maxLength'),
                },
                required: {
                  value: true,
                  message: t('forms:profileSettings.lastName.required'),
                },
              }}
              defaultValue={account?.lastName ?? ''}
              control={control}
              name="lastName"
              render={({ field: { value, name, onChange } }) => {
                return (
                  <Input
                    required
                    disabled={isUpdateAccountLoading}
                    label={t('forms:profileSettings.lastName.label')}
                    value={value}
                    autoComplete="family-name"
                    placeholder={t(
                      'forms:profileSettings.lastName.placeholder',
                    )}
                    name={name}
                    onKeyDown={onInputKeyEnter}
                    onBlur={onInputBlur}
                    onChange={onChange}
                    error={errors?.lastName?.message}
                  />
                );
              }}
            />
          </div>
        </div>
      </Section>
      <AccountSecuritySection />
      <DeleteAccountSection />
    </React.Fragment>
  );
}
