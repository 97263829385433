import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UserContext } from '@/contexts/UserContext';
import { revokeShareProject } from '@/services/api/workspace/projects';
import { TSharedProjectInfo } from '@/types/timeline';

import { onRevokeSuccess } from './on-success.util';

type ShareProjctProp = {
  projectId: string;
  shareId: string;
};
export default function useRevokeShareProject() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();

  return useMutation<TSharedProjectInfo | null, Error, ShareProjctProp>({
    mutationFn: async ({ projectId, shareId }) => {
      if (!workspaceId) return null;
      return revokeShareProject({ workspaceId, projectId, shareId });
    },
    onSuccess: (_data, { projectId }) =>
      onRevokeSuccess({
        projectId,
        workspaceId: workspaceId ?? '',
        queryClient,
      }),
  });
}
