import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { http } from '@/services/api';
import { normalizeSubscription } from '@/services/normalizer';
import { BaseResponse } from '@/types/base-responses';
import { TSubscriptionDto } from '@/types/workspace';

import { CAN_DELETE_ACCOUNT_KEY } from '../account/useCanDeleteAccount';
import { GET_SUBSCRIPTION_KEY } from '../workspace/useGetSubscription';

export default function useCancelSubscription() {
  const { workspace, account } = useContext(UserContext);
  const queryClient = useQueryClient();

  const { addNotification } = useContext(NotificationsContext);

  return useMutation({
    mutationFn: async (subscriptionId: string) => {
      if (workspace?.id) {
        const {
          data: { data },
        } = await http.post<BaseResponse<TSubscriptionDto>>(
          `/workspaces/${workspace?.id}/subscriptions/${subscriptionId}/cancel`,
        );
        return normalizeSubscription(data);
      }
    },
    onMutate: () => {
      const subscription = queryClient.getQueryData<TSubscriptionDto>([
        GET_SUBSCRIPTION_KEY,
        workspace?.id,
      ]);
      if (!subscription) return undefined;
      const newSubscription = cloneDeep(subscription);
      newSubscription.waitingForUpdate = true;
      queryClient.setQueryData<TSubscriptionDto>(
        [GET_SUBSCRIPTION_KEY, workspace?.id],
        newSubscription,
      );

      return { subscription };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CAN_DELETE_ACCOUNT_KEY, account?.email],
      });
    },
    onError: (error: Error, _subscriptionId, context) => {
      if (context?.subscription) {
        queryClient.setQueryData<TSubscriptionDto>(
          [GET_SUBSCRIPTION_KEY, workspace?.id],
          context.subscription,
        );
      }
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
