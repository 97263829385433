import { ComponentProps, useCallback, useContext } from 'react';

import classNames from 'classnames';
import { validate as EmailValidator } from 'email-validator';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';

import { UserContext } from '@/contexts/UserContext';
import useInviteToWorkspace from '@/hooks/workspace/resources/useInviteToWorkspace';
import { WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TResourceSearchResult } from '@/types/timeline';

import Button from '@/components/Button';
import Input from '@/components/Input';
import SelectDropdown from '@/components/SelectDropdown';

import Modal from '../Modal';
import styles from './styles.module.css';

type ModalProps = Omit<ComponentProps<typeof Modal>, 'modalKey'> & {
  resource: TResourceSearchResult;
};
export default function ModalInviteToWorkspace({
  isOpen,
  onClose,
  resource,
}: ModalProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; permission: WORKSPACE_MEMBER_PERMISSION }>({
    mode: 'onSubmit',
  });

  const { mutate: invite, isPending } = useInviteToWorkspace();

  const { workspace } = useContext(UserContext);

  const onSubmitFn = useCallback(
    (data: { email: string; permission: WORKSPACE_MEMBER_PERMISSION }) => {
      if (workspace?.id)
        invite(
          {
            ...data,
            resourceId: resource.id,
            workspaceId: workspace?.id,
          },
          { onSuccess: onClose },
        );
    },
    [invite, onClose, resource.id, workspace?.id],
  );

  return (
    <Modal
      title={`Invite ${resource.firstName} ${resource.lastName} to workspace`}
      modalKey={'invite-to-workspace'}
      enableClose
      isOpen={isOpen}
      onClose={onClose}
      footer={undefined}
    >
      <form
        className={styles.modalContainer}
        onSubmit={handleSubmit(onSubmitFn)}
      >
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <div className={classNames(styles.field, styles.field_2x)}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: t('forms:signIn.email.required'),
                  },
                  validate: (value) => {
                    if (!EmailValidator(value)) {
                      return t('forms:signIn.email.invalid') as string;
                    }
                    return true;
                  },
                }}
                control={control}
                name="email"
                render={({ field: { value, name, onChange, onBlur } }) => {
                  return (
                    <Input
                      onBlur={onBlur}
                      name={name}
                      type="email"
                      size="medium"
                      disabled={isPending}
                      value={value}
                      placeholder={t('common:placeholderEmail')}
                      onChange={onChange}
                      error={errors?.email?.message as string}
                    />
                  );
                }}
              />
            </div>
            <div className={styles.field}>
              <Controller
                control={control}
                disabled={isPending}
                defaultValue={WORKSPACE_MEMBER_PERMISSION.READ_ONLY}
                rules={{
                  required: {
                    value: true,
                    message: t('forms:signIn.password.required') || 'invalid',
                  },
                }}
                name="permission"
                render={({ field: { name, onChange, value } }) => (
                  <div className={styles.permissionWrapper}>
                    {/* <span className={styles.dropdownLabel}>{titleCase(name)}</span> */}
                    <SelectDropdown
                      className={styles.permissionDropdown}
                      size="medium"
                      placeholder={name}
                      label={t(
                        `common:modals.roles.${
                          value === WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE
                            ? 'editor'
                            : 'viewer'
                        }.label`,
                      )}
                    >
                      <SelectDropdown.Item
                        label={t('common:modals.roles.editor.label')}
                        subLabel={t('common:modals.roles.editor.description')}
                        onSelect={() =>
                          onChange(WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE)
                        }
                        checked={
                          value === WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE
                        }
                      />
                      <SelectDropdown.Item
                        label={t('common:modals.roles.viewer.label')}
                        subLabel={t('common:modals.roles.viewer.description')}
                        onSelect={() =>
                          onChange(WORKSPACE_MEMBER_PERMISSION.READ_ONLY)
                        }
                        checked={
                          value === WORKSPACE_MEMBER_PERMISSION.READ_ONLY
                        }
                      />
                    </SelectDropdown>
                  </div>
                )}
              />
            </div>
          </div>
          <span className={styles.description}>
            Will receive an email with an invite to sign up.
          </span>
        </div>
        <div className={styles['action-wrapper']}>
          <Button
            label={'Invite to workspace'}
            size="medium"
            disabled={isPending}
            isLoading={isPending}
          />
          <Button
            label={t('common:cancel')}
            size="medium"
            variant="ghost"
            onClick={onClose}
          />
        </div>
      </form>
    </Modal>
  );
}
