import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import { UserContext } from '@/contexts/UserContext';
import { http } from '@/services/api';
import { normalizeCanDeleteAccount } from '@/services/normalizer';
import { TCanDeleteAccount } from '@/types/account';
import { BaseResponse } from '@/types/base-responses';

export const CAN_DELETE_ACCOUNT_KEY = 'get-can-delete-account';
export default function useCanDeleteAccount(
  {
    enabled = true,
  }: {
    enabled: boolean;
  } = { enabled: true },
) {
  const { account } = useContext(UserContext);

  return useQuery({
    queryKey: [CAN_DELETE_ACCOUNT_KEY, account?.email],
    queryFn: async () => {
      const canDeleteAccount = await http.get<BaseResponse<TCanDeleteAccount>>(
        '/accounts/me/can-delete',
      );
      return normalizeCanDeleteAccount(canDeleteAccount.data.data);
    },
    enabled,
  });
}
