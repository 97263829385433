import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { t } from 'i18next';
import { first, slice } from 'lodash';

import useFirebaseUploader from '@/hooks/firebase/useFirebaseUploader';
import { normalizeFileToAttachmentDataUrl } from '@/services/normalizer';
import { STORAGE_BASE_PATH } from '@/types/enums';
import { TAttachmentDataUrls } from '@/types/generic';

import styles from './styles.module.css';
import { UserContext } from '../../contexts/UserContext';
import Button from '../Button';
import FileItem from '../FileItem';

type Props = {
  label?: string;
  resourceName: string;
  multiple?: boolean;
  basePath: STORAGE_BASE_PATH;
  showSmallButton?: boolean;
  onRemoveFileSuccess: (id: string) => void;
  onUploadSuccess: (data: TAttachmentDataUrls) => void;
  data: TAttachmentDataUrls[];
  onChange: (data: TAttachmentDataUrls[]) => void;
  // getIfIsUploading: (isUploading: boolean) => void;
};
export default function AvatarUploader({
  label,
  multiple,
  onRemoveFileSuccess,
  data,
  onChange,
  resourceName,
  onUploadSuccess,
  basePath,
}: // getIfIsUploading,
Props) {
  const types = ['image/png', 'image/jpeg'];
  const { workspaceId } = useContext(UserContext);
  const { mutateAsync, isPending } = useFirebaseUploader(
    `plann/${workspaceId}/${basePath}/avatar`,
    onUploadSuccess,
  );
  const [imageFiles, setImageFiles] = useState<File[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  const onUploadImage = useCallback(
    async (files: File[]) => {
      await Promise.all(
        files?.map(async (file) => {
          return mutateAsync({ file });
        }),
      );
    },
    [mutateAsync],
  );

  useEffect(() => {
    if (imageFiles?.length) {
      const filesChunk = slice(imageFiles, 0, 3);
      onUploadImage(filesChunk).then(() => {
        setImageFiles((prev) => slice(prev, filesChunk.length));
      });
    }
  }, [imageFiles, onUploadImage]);

  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const targetFiles = e.target.files || [];
      const allFiles = [...targetFiles];
      onChange([...data, ...allFiles.map(normalizeFileToAttachmentDataUrl)]);
      setImageFiles([...imageFiles, ...allFiles]);
    },
    [onChange, data, imageFiles],
  );

  const firstAvatar = first(data);

  const src = firstAvatar?.url || firstAvatar?.ref;

  return (
    <div className={styles.container}>
      {label && <span className={styles.label}>{label}</span>}
      {src && (
        <FileItem
          name={resourceName}
          onDelete={onRemoveFileSuccess}
          item={firstAvatar}
        />
      )}
      <span className={styles.wrapper}>
        <label
          style={{
            cursor: 'pointer',
            pointerEvents: isPending ? 'none' : 'auto',
            opacity: !isPending ? 1 : 0,
          }}
          htmlFor="uploader"
        >
          {!firstAvatar?.ref && (
            <div style={{ pointerEvents: 'none' }}>
              <Button
                label={t('common:uploadImage')}
                variant="outline"
                size="medium"
                onClick={() => inputRef?.current?.click()}
              />
            </div>
          )}
          <input
            type="file"
            multiple={multiple}
            id="uploader"
            style={{ display: 'none' }}
            accept={types.join(',')}
            ref={inputRef}
            onChange={handleChange}
          />
        </label>
      </span>
    </div>
  );
}
