import {
  Label,
  Radio,
  RadioGroup,
} from '@headlessui/react';
import { IconCheck } from '@tabler/icons-react';

import { getCssVariable } from '@/services/helpers';
import { PROJECT_COLOR } from '@/types/enums';

import styles from './styles.module.css';

type Props = {
  color: PROJECT_COLOR;
  onChange: (color: PROJECT_COLOR) => void;
};
export default function ColorSelector({ color, onChange }: Props) {
  return (
    <RadioGroup className={styles.container} value={color} onChange={onChange}>
      <Label className={styles.colorSelectorLabel} style={{width: '100%'}}>Choose a color</Label>
      <Radio value={PROJECT_COLOR.GREY}>
        {({ checked }) => (
          <div
            className={styles.option}
            style={{
              backgroundColor: getCssVariable('--color-neutral-700'),
            }}
          >
            {checked && <IconCheck color={'white'} />}
          </div>
        )}
      </Radio>
      <Radio value={PROJECT_COLOR.RED}>
        {({ checked }) => (
          <div
            className={styles.option}
            style={{
              backgroundColor: getCssVariable('--color-red-700'),
            }}
          >
            {checked && <IconCheck color={'white'} />}
          </div>
        )}
      </Radio>
      <Radio value={PROJECT_COLOR.ORANGE}>
        {({ checked }) => (
          <div
            className={styles.option}
            style={{
              backgroundColor: getCssVariable('--color-orange-700'),
            }}
          >
            {checked && <IconCheck color={'white'} />}
          </div>
        )}
      </Radio>
      <Radio value={PROJECT_COLOR.LIME}>
        {({ checked }) => (
          <div
            className={styles.option}
            style={{
              backgroundColor: getCssVariable('--color-lime-700'),
            }}
          >
            {checked && <IconCheck color={'white'} />}
          </div>
        )}
      </Radio>
      <Radio value={PROJECT_COLOR.GREEN}>
        {({ checked }) => (
          <div
            className={styles.option}
            style={{
              backgroundColor: getCssVariable('--color-green-700'),
            }}
          >
            {checked && <IconCheck color={'white'} />}
          </div>
        )}
      </Radio>
      <Radio value={PROJECT_COLOR.TEAL}>
        {({ checked }) => (
          <div
            className={styles.option}
            style={{
              backgroundColor: getCssVariable('--color-teal-700'),
            }}
          >
            {checked && <IconCheck color={'white'} />}
          </div>
        )}
      </Radio>
      <Radio value={PROJECT_COLOR.BLUE}>
        {({ checked }) => (
          <div
            className={styles.option}
            style={{
              backgroundColor: getCssVariable('--color-blue-700'),
            }}
          >
            {checked && <IconCheck color={'white'} />}
          </div>
        )}
      </Radio>
      <Radio value={PROJECT_COLOR.INDIGO}>
        {({ checked }) => (
          <div
            className={styles.option}
            style={{
              backgroundColor: getCssVariable('--color-indigo-700'),
            }}
          >
            {checked && <IconCheck color={'white'} />}
          </div>
        )}
      </Radio>
      <Radio value={PROJECT_COLOR.PURPLE}>
        {({ checked }) => (
          <div
            className={styles.option}
            style={{
              backgroundColor: getCssVariable('--color-purple-700'),
            }}
          >
            {checked && <IconCheck color={'white'} />}
          </div>
        )}
      </Radio>
      <Radio value={PROJECT_COLOR.PINK}>
        {({ checked }) => (
          <div
            className={styles.option}
            style={{
              backgroundColor: getCssVariable('--color-pink-700'),
            }}
          >
            {checked && <IconCheck color={'white'} />}
          </div>
        )}
      </Radio>
    </RadioGroup>
  );
}
