import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import {
  differenceInCalendarWeeks,
  isBefore,
  setDefaultOptions,
} from 'date-fns';

import { linearMap, weeksToPixels } from '@/services/helpers';
import { PROJECT_STATUS } from '@/types/enums';
import { TAttachmentDataUrls } from '@/types/generic';
import { TResourceItemList, TTimeBlockRange } from '@/types/timeline';

import BlockWrapContent from './Content';
import BlockContext, { MouseEventRef } from './Context';
import styles from './styles.module.css';

import { SharedAppContext } from '@/shared-app/context/SharedAppContext';
import { TimelineProjectContext } from '@/shared-app/context/TimelineProjectContext';
import { ProjectInfo } from '@/shared-app/types/project-info';

export type Props = {
  currentResource: TResourceItemList;
  project?: ProjectInfo;
  block: TTimeBlockRange;
  isActive?: boolean;
  avatar?: TAttachmentDataUrls;
};

const BlockWrap = ({ block, currentResource, project, avatar }: Props) => {
  setDefaultOptions({ weekStartsOn: 1 });

  const mouseEventRef = useRef<MouseEventRef>({});

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_shiftIsPressed, setShiftIsPressed] = useState(false);
  const [localProps, setLocalProps] = useState(block);

  const { timeInterval, weekSizeInPx } = useContext(TimelineProjectContext);

  const { layoutIsExpanded } = useContext(SharedAppContext);

  const lengthWeeks = differenceInCalendarWeeks(
    localProps.end,
    localProps.start,
  );

  const startPixels = weeksToPixels(
    localProps.start,
    timeInterval.start,
    false,
    weekSizeInPx,
  );

  const endPixels = weeksToPixels(
    localProps.end,
    timeInterval.start,
    true,
    weekSizeInPx,
  );

  const lengthOfBlockInPx = endPixels - startPixels;

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    document.addEventListener('keydown', onKeydownFn);
    document.addEventListener('keyup', onKeyupFn);
    return () => {
      document.removeEventListener('keydown', onKeydownFn);
      document.removeEventListener('keyup', onKeyupFn);
    };
  }, []);

  const onKeydownFn = (event: KeyboardEvent) => {
    // detect if shift is pressed
    if (event.key === 'Shift') {
      setShiftIsPressed(true);
    }
  };

  const onKeyupFn = () => {
    // detect if shift is pressed
    setShiftIsPressed(false);
  };

  const [_hovered, setHovered] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const handleMouseOver = useCallback(() => {
    timerRef.current = setTimeout(() => setHovered(true), 800);
  }, []);

  const handleMouseOut = useCallback(() => {
    if (!timerRef?.current) {
      return;
    }
    clearTimeout(timerRef.current);
    setHovered(false);
  }, []);

  useEffect(() => {
    const node = ref?.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [handleMouseOut, handleMouseOver]);

  // EFFECTS
  useEffect(() => setLocalProps(block), [block]);

  const isPast = isBefore(localProps.end, new Date());
  const allocation = block.allocation;

  const clampedAllocation = useMemo(() => {
    const allocValue = Math.round(
      Math.min(Math.max(allocation, 1), currentResource?.capacity ?? 1),
    );
    return Math.floor(
      linearMap(allocValue, 1, currentResource?.capacity, 1, 5),
    );
  }, [allocation, currentResource?.capacity]);

  const shadeClass = useMemo(
    () => `shade-${project?.color}-${clampedAllocation}`,
    [clampedAllocation, project?.color],
  );

  const [shouldShowTooltip, _setShouldShowTooltip] = useState(false);
  // RENDER
  return (
    <BlockContext.Provider
      value={{
        localProps,
        mouseEventRef,
        lengthWeeks,
        resourceId: currentResource?.id ?? '',
        shouldShowTooltip,
        resourceName: `${currentResource?.firstName} ${currentResource?.lastName}`,
        avatar,
        block,
      }}
    >
      <div
        ref={ref}
        onClick={(event) => event.stopPropagation()}
        className={classNames([styles.container, styles[shadeClass]], {
          [styles.isPast]: isPast,
          [styles.isExpanded]: layoutIsExpanded,
          [styles[`isUnconfirmed-${clampedAllocation}`]]:
            project?.status === PROJECT_STATUS.UNCONFIRMED,
        })}
        style={{ left: startPixels + 1, width: lengthOfBlockInPx - 1 }}
        aria-hidden
      >
        <BlockWrapContent />
      </div>
    </BlockContext.Provider>
  );
};

export default BlockWrap;
