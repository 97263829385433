import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import type { Virtualizer } from '@tanstack/react-virtual';
import { elementScroll, useVirtualizer } from '@tanstack/react-virtual';
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import {
  add,
  addWeeks,
  compareAsc,
  compareDesc,
  differenceInWeeks,
  eachWeekOfInterval,
  endOfDay,
  setDefaultOptions,
  startOfDay,
  startOfToday,
  startOfWeek,
  sub,
  subWeeks,
} from 'date-fns';
import { noop, uniqBy } from 'lodash';

import { weeksToPixels } from '@/services/helpers';
import { TResourceItemList } from '@/types/timeline';

import { SharedAppContext } from './SharedAppContext';
import useGetProjectProgression from '../hooks/useGetProjectProgression';
import useGetPublicProjectResources from '../hooks/useGetPublicProject';
import useGetPublicProjectTimeline from '../hooks/useGetPublicProjectTimeline';
import useProjectInfo from '../hooks/useProjectInfo';
import { ProjectInfo, ProjectProgression } from '../types/project-info';

export const WEEK_ROW_HIGH_SIZE_COMPRESSED = 56;
export const WEEK_ROW_HIGH_SIZE_EXPANDED = 80;
export const WEEKS_TO_ADD = 20;
export const WORKING_DAYS_IN_A_WEEK = 5;

type TTimeInterval = { when?: 'before' | 'after'; start: Date; end: Date };

export type TData = {
  resources: TResourceItemList[];
  totalAllocation: number;
};

export enum SORTING_OPTIONS {
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  MANUAL = 'manual',
}

const TimelineProjectContext = React.createContext<{
  currentScrollSize: number;
  isFetching: boolean;
  scrollToBlock: (
    block: TTimeInterval & { position: 'start' | 'end' | 'center' },
  ) => void;
  onNextFn: () => void;
  onPrevFn: () => void;
  projectInfo?: ProjectInfo;
  setCurrentScrollSize: React.Dispatch<React.SetStateAction<number>>;
  setResetViewOnToday: React.Dispatch<React.SetStateAction<boolean>>;
  setExpandedByIds: React.Dispatch<React.SetStateAction<string[]>>;
  resetViewOnToday: boolean;
  expandedByIds: string[] | null;
  isLoading: boolean;
  weekSizeInPx: number;
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  ref: React.RefObject<HTMLDivElement | null>;
  todayRef: React.RefObject<HTMLDivElement | null>;
  scrollTodayIntoView: (val?: boolean) => void;
  updateTimeInterval: (when: 'before' | 'after') => void;
  timeInterval: TTimeInterval;
  weeks: Date[];
  onExpandOrCompressAllFn: () => void;
  data?: TData;
}>({
  currentScrollSize: 0,
  isLoading: false,
  onNextFn: noop,
  isFetching: false,
  onPrevFn: noop,
  projectInfo: undefined,
  todayRef: {} as React.RefObject<HTMLDivElement>,
  setExpandedByIds: noop,
  onExpandOrCompressAllFn: noop,
  expandedByIds: [],
  weekSizeInPx: WEEK_ROW_HIGH_SIZE_COMPRESSED,
  setCurrentScrollSize: noop,
  virtualizer: {} as Virtualizer<HTMLDivElement, Element>,
  resetViewOnToday: true,
  ref: {} as React.RefObject<HTMLDivElement>,
  setResetViewOnToday: noop,
  updateTimeInterval: noop,
  scrollTodayIntoView: noop,
  timeInterval: { start: new Date(), end: new Date() },
  weeks: [],
  data: undefined,
  scrollToBlock: noop,
});

const TimelineProjectProvider = ({ children }: PropsWithChildren) => {
  setDefaultOptions({ weekStartsOn: 1 });
  const { layoutIsExpanded } = useContext(SharedAppContext);

  const [firstScoll, setFirstScroll] = useState<boolean>(false);
  const [fetchData, setFetchData] = useState<boolean>(false);
  const [expandedByIds, setExpandedByIds] = useLocalStorage<string[]>(
    `compressed-projects`,
    [],
  );

  const [currentScrollSize, setCurrentScrollSize] = useState(0);
  const [resetViewOnToday, setResetViewOnToday] = useState(true);
  const todayRef = useRef<HTMLDivElement>(null);

  const today = startOfToday();

  const currentWeek = startOfWeek(today);

  const initialTimeInterval = {
    start: sub(currentWeek, { weeks: 40 }),
    end: add(currentWeek, { weeks: 40 }),
  };

  const [timeInterval, setTimeInterval] =
    useState<TTimeInterval>(initialTimeInterval);

  const { data: project } = useProjectInfo();

  const weekSizeInPx = layoutIsExpanded
    ? WEEK_ROW_HIGH_SIZE_EXPANDED
    : WEEK_ROW_HIGH_SIZE_COMPRESSED;

  const weeks = useMemo(() => eachWeekOfInterval(timeInterval), [timeInterval]);

  const virtualizer = useVirtualizer({
    count: weeks.length,
    horizontal: true,
    overscan: 10,
    getScrollElement: () => ref.current,
    estimateSize: () => weekSizeInPx,
  });

  const {
    data: progression,
    isPending,
    isFetched,
  } = useGetProjectProgression({
    onSuccess: useCallback(
      (progression: ProjectProgression | undefined) => {
        if (progression && !progression.current) {
          if (progression.future) {
            if (
              compareDesc(
                progression.future.endDate,
                subWeeks(timeInterval.end, 20),
              ) < 0
            ) {
              setTimeInterval({
                start: timeInterval.start,
                end: add(new Date(progression.future.startDate), {
                  weeks: 20,
                }),
              });
            }
          } else if (progression.past) {
            if (
              compareAsc(
                progression.past.endDate,
                addWeeks(timeInterval.start, 20),
              ) < 0
            ) {
              setTimeInterval({
                start: subWeeks(new Date(progression.past.startDate), 20),
                end: timeInterval.end,
              });
            }
          }
        }
        setFetchData(true);
      },
      [timeInterval.end, timeInterval.start],
    ),
  });

  const scrollTodayIntoView = useCallback(
    (smooth: boolean = true) => {
      if (todayRef.current?.dataset?.['position']) {
        elementScroll(
          parseFloat(todayRef.current.dataset['position']) -
            (virtualizer.scrollRect?.width ?? window.innerWidth) / 2,
          { behavior: smooth ? 'smooth' : 'auto' },
          virtualizer,
        );
      }
    },
    [virtualizer],
  );

  const ref = useRef<HTMLDivElement>(null);

  const scrollToBlock = useCallback(
    ({
      start,
      end,
      position = 'center',
    }: TTimeInterval & { position: 'start' | 'end' | 'center' }) => {
      const diffInWeeks = differenceInWeeks(end, start) + 1;
      const diffInPx = diffInWeeks * weekSizeInPx;
      const halfDiffInPx = diffInPx / 2;
      const pxFromStart = weeksToPixels(
        start,
        timeInterval.start,
        false,
        weekSizeInPx,
      );
      const halfScreen = window.innerWidth / 2;
      switch (position) {
        case 'start':
          elementScroll(
            pxFromStart - halfScreen,
            { behavior: 'smooth' },
            virtualizer,
          );
          break;
        case 'end':
          elementScroll(
            pxFromStart + diffInPx - halfScreen,
            { behavior: 'smooth' },
            virtualizer,
          );
          break;
        case 'center':
          elementScroll(
            pxFromStart + halfDiffInPx - halfScreen,
            { behavior: 'smooth' },
            virtualizer,
          );
          break;
      }
    },
    [timeInterval.start, virtualizer, weekSizeInPx],
  );

  useEffect(() => {
    if (!firstScoll && !fetchData) scrollTodayIntoView(false);
  }, [fetchData, firstScoll, scrollTodayIntoView]);

  const scrollBasedOnProgression = useCallback(() => {
    if (firstScoll) return;
    if (
      !progression ||
      progression.current ||
      (!progression.future && !progression.past)
    ) {
      scrollTodayIntoView(false);
    } else {
      if (progression.future) {
        scrollToBlock({
          start: new Date(progression.future.startDate),
          end: new Date(progression.future.endDate),
          position: 'start',
        });
      } else if (progression.past) {
        scrollToBlock({
          start: new Date(progression.past.startDate),
          end: new Date(progression.past.endDate),
          position: 'end',
        });
      }
    }
    setFirstScroll(true);
  }, [firstScoll, progression, scrollToBlock, scrollTodayIntoView]);

  const { data: resourceData } = useGetPublicProjectResources();

  const {
    data: projectTimeline,
    fetchNextPage,
    fetchPreviousPage,
    ...other
  } = useGetPublicProjectTimeline({
    timeInterval,
    getNextPageParam: (_lastPage) => ({
      start: startOfDay(add(timeInterval.end, { days: 1 })),
      end: add(timeInterval.end, { weeks: WEEKS_TO_ADD }),
    }),
    getPreviousPageParam: (_lastPage) => ({
      start: sub(timeInterval.start, { weeks: WEEKS_TO_ADD }),
      end: endOfDay(sub(timeInterval.start, { days: 1 })),
    }),
    enabled: !isPending && isFetched && fetchData,
    onSuccess: scrollBasedOnProgression,
  });

  const data = useMemo(() => {
    const mergedMap: Map<string, TResourceItemList> = new Map();
    // flatten all data pages
    projectTimeline?.pages?.forEach((pageData) => {
      pageData.forEach((resource) => {
        const resObj = mergedMap.get(resource.id) ?? resource;
        resObj.timeblocks = uniqBy(
          [...(resObj.timeblocks ?? []), ...(resource.timeblocks ?? [])],
          'id',
        );
        mergedMap.set(resource.id, resObj);
      });
    }, [] as TResourceItemList[]);

    const data = resourceData?.resources?.map((resource) => {
      const resObj = mergedMap.get(resource.id);
      if (resObj) return Object.assign(resource, resObj);
      return resource;
    });
    return {
      resources: data,
      totalAllocation: resourceData?.totalAllocation ?? 0,
    };
  }, [
    projectTimeline?.pages,
    resourceData?.resources,
    resourceData?.totalAllocation,
  ]);

  const onExpandOrCompressAllFn = useCallback(() => {
    if (expandedByIds?.length) {
      setExpandedByIds([]);
    } else {
      setExpandedByIds(data.resources?.map((r) => r.id) ?? []);
    }
  }, [data, expandedByIds?.length, setExpandedByIds]);

  const updateTimeInterval = (when: 'before' | 'after') => {
    let newTimeInterval;
    if (when === 'before') {
      newTimeInterval = {
        start: sub(timeInterval.start, { weeks: WEEKS_TO_ADD }),
        end: timeInterval.end,
      };
      fetchPreviousPage();
    } else {
      newTimeInterval = {
        start: timeInterval.start,
        end: add(timeInterval.end, { weeks: WEEKS_TO_ADD }),
      };
      fetchNextPage();
    }
    setTimeInterval({ when, ...newTimeInterval });
  };

  const onNextFn = useCallback(() => {
    if (virtualizer.isScrolling) return;
    virtualizer.scrollBy(weekSizeInPx * 8, { behavior: 'auto' });
  }, [virtualizer, weekSizeInPx]);

  const onPrevFn = useCallback(() => {
    if (virtualizer.isScrolling) return;
    virtualizer.scrollBy(-weekSizeInPx * 8, { behavior: 'auto' });
  }, [virtualizer, weekSizeInPx]);

  useEffect(() => {
    virtualizer.measure();
    setCurrentScrollSize((prevValue) => {
      if (prevValue !== 0) {
        const newValue = layoutIsExpanded
          ? (WEEK_ROW_HIGH_SIZE_EXPANDED * prevValue) /
            WEEK_ROW_HIGH_SIZE_COMPRESSED
          : (WEEK_ROW_HIGH_SIZE_COMPRESSED * prevValue) /
            WEEK_ROW_HIGH_SIZE_EXPANDED;
        virtualizer.scrollToOffset(newValue);
        return newValue;
      } else {
        return prevValue;
      }
    });
  }, [layoutIsExpanded, virtualizer]);

  useEffect(() => {
    setCurrentScrollSize((prevValue) =>
      virtualizer.scrollOffset !== prevValue
        ? (virtualizer.scrollOffset ?? 0)
        : prevValue,
    );
  }, [setCurrentScrollSize, virtualizer.scrollOffset]);

  return (
    <TimelineProjectContext.Provider
      value={{
        data: data as {
          resources: TResourceItemList[];
          totalAllocation: number;
        },
        currentScrollSize,
        setCurrentScrollSize,
        virtualizer,
        weekSizeInPx,
        expandedByIds: expandedByIds,
        setExpandedByIds: setExpandedByIds,
        scrollToBlock,
        ref,
        resetViewOnToday,
        onExpandOrCompressAllFn,
        setResetViewOnToday,
        isLoading: other?.isLoading ?? false,
        updateTimeInterval,
        timeInterval,
        weeks,
        onNextFn,
        onPrevFn,
        isFetching: other?.isFetching ?? false,
        todayRef,
        scrollTodayIntoView,
        projectInfo: project,
      }}
    >
      {children}
    </TimelineProjectContext.Provider>
  );
};

export { TimelineProjectContext, TimelineProjectProvider };
