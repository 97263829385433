import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import { handleApiCall } from '@/services/api';
import { BaseResponse } from '@/types/base-responses';
import { TSuccessErrorHook } from '@/types/generic';

import { SharedAppContext } from '../context/SharedAppContext';
import { ProjectProgression } from '../types/project-info';

export const GET_PROJECT_PROGRESSION = 'get-project-progression';
export default function useGetProjectProgression({
  onSuccess,
  onError,
}: TSuccessErrorHook<ProjectProgression> = {}) {
  const { publicHttpClient, clientInitialized } = useContext(SharedAppContext);
  return useQuery({
    queryKey: [GET_PROJECT_PROGRESSION],
    queryFn: () => {
      return handleApiCall(
        publicHttpClient
          .get<BaseResponse<ProjectProgression>>('/timeline/progression')
          .then((response) => response.data.data),
        onSuccess,
        onError,
      );
    },
    enabled: clientInitialized,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
