import { useContext, useState } from 'react';

import { IconSelector } from '@tabler/icons-react';
import classNames from 'classnames';

import { shrinkValue } from '@/services/helpers';

import Avatar from '@/components/Avatar';
import Portal from '@/components/Portal';

import styles from './styles.module.css';

import { TimelineProjectContext } from '@/shared-app/context/TimelineProjectContext';

export default function TimelineDetails() {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
  const { data: timelineResources, projectInfo } = useContext(
    TimelineProjectContext,
  );
  const mode = projectInfo?.mode?.toLowerCase();
  return (
    <Portal>
      <div
        className={classNames(styles.detailsWrapper, {
          [styles.opened]: isOpen && timelineResources?.resources?.length,
        })}
      >
        <div className={styles.listWrapper}>
          <div className={styles.list}>
            {timelineResources?.resources?.map((item) => {
              return (
                <div className={styles.listItem} key={item.id}>
                  <Avatar
                    size="small"
                    name={`${item.firstName} ${item.lastName}`}
                  />
                  <div className={styles.name}>
                    {item.firstName} {item.lastName}
                  </div>
                  <div className={styles.totalAllocation}>
                    {shrinkValue(item.totalAllocation)} {mode}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.heading}>
          <button
            onClick={() => setIsOpen((op) => !op)}
            disabled={!timelineResources?.resources?.length}
            className={styles.detailsButton}
          >
            <div className={styles.iconText}>
              <IconSelector size={20} />
              <div className={styles.text}>Total</div>
            </div>
            <div className={styles.totalAllocation}>
              {shrinkValue(timelineResources?.totalAllocation ?? 0)} {mode}
            </div>
          </button>
        </div>
      </div>
    </Portal>
  );
}
