import { useContext, useMemo } from 'react';

import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { t } from 'i18next';
import { find, isUndefined, noop, reduce } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { handleApiCall } from '@/services/api';
import { getTimelineResources } from '@/services/api/workspace/resources';
import { mergeResourcesData } from '@/services/helpers/timelines/resources';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import {
  TTimeInterval,
  TTimelineProject,
  TTimelineResource,
} from '@/types/timeline';

import useResourcesQuery from './useResourcesQuery';
import useHiddenProjectQuery from '../projects/useHiddenProjectQuery';

type TTimelineResourceQueryParam = TSuccessErrorHook<TTimelineResource[]> & {
  timeInterval: TTimeInterval;
  getNextPageParam: (
    lastPage: TTimelineResource[],
  ) => TTimeInterval | undefined;
  getPreviousPageParam: (
    lastPage: TTimelineResource[],
  ) => TTimeInterval | undefined;
};

export const TIMELINE_RESOURCE_QUERY_KEY = 'get-timeline-resource';
export default function useTimelineResourceQuery({
  enabled = true,
  //onSuccess = noop,
  onError = noop,
  timeInterval,
  getNextPageParam,
  getPreviousPageParam,
}: TTimelineResourceQueryParam) {
  const queryClient = useQueryClient();
  const { workspaceId } = useContext(UserContext);

  const onUseResourceQuerySuccessFn = () => {
    queryClient.invalidateQueries({
      queryKey: [TIMELINE_RESOURCE_QUERY_KEY, workspaceId],
    });
  };

  const { data: resourceData, isLoading: resourceLoading } = useResourcesQuery({
    enabled: !!workspaceId,
    onSuccess: onUseResourceQuerySuccessFn,
  });

  const { addNotification } = useContext(NotificationsContext);

  const { data: timelineHiddenProjectData, isLoading: hiddenIsLoading } =
    useHiddenProjectQuery({
      enabled: resourceData && !resourceLoading,
    });

  const isEnabled =
    enabled &&
    !resourceLoading &&
    !hiddenIsLoading &&
    !isUndefined(resourceData);

  const { data: pagedTimelineResourceData, ...others } = useInfiniteQuery<
    TTimelineResource[],
    Error,
    InfiniteData<TTimelineResource[]>,
    [string, string | undefined | null],
    TTimeInterval
  >({
    queryKey: [TIMELINE_RESOURCE_QUERY_KEY, workspaceId],
    initialPageParam: timeInterval,
    queryFn: async ({ pageParam = timeInterval }) => {
      return handleApiCall(
        getTimelineResources({
          workspaceId,
          startDate: pageParam.start.getTime(),
          endDate: pageParam.end.getTime(),
        }),
        undefined,
        (error: TResponseError) => {
          onError(error);
          addNotification({
            type: 'error',
            title: t('errors:generic.title') as string,
            description:
              error?.message ?? (t('errors:generic.description') as string),
          });
        },
      );
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEnabled,
    getNextPageParam,
    getPreviousPageParam,
  });

  const data = useMemo(() => {
    // flatten all data pages
    const timelineResourceData = pagedTimelineResourceData?.pages?.reduce(
      (allPagesData, pageData) => {
        return allPagesData?.length === 0
          ? pageData
          : mergeResourcesData(allPagesData, pageData);
      },
      [],
    );

    return reduce(
      resourceData,
      (acc, resource) => {
        const timelineResource = find(timelineResourceData, {
          id: resource.id,
        });

        const timelineResourceHidden = find(timelineHiddenProjectData, {
          id: resource.id,
        });

        if (timelineResource) {
          return [
            ...acc,
            {
              ...resource,
              hidden: timelineResourceHidden?.hidden ?? [],
              hiddenCount: resource?.hiddenCount ?? 0,
              projects: resource.projects.map((p) => {
                const timelineProject = find(timelineResource.projects, {
                  id: p.id,
                });
                return timelineProject
                  ? ({
                      ...p,
                      timeblocks: timelineProject.timeblocks,
                    } as TTimelineProject)
                  : (p as TTimelineProject);
              }),
            },
          ];
        }

        return [...acc, resource as TTimelineResource];
      },
      [] as TTimelineResource[],
    );
  }, [resourceData, timelineHiddenProjectData, pagedTimelineResourceData]);

  return {
    data,
    ...others,
  };
}
