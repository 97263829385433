import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import { UserContext } from '@/contexts/UserContext';
import { getWorkloadsPreferences } from '@/services/api/workspace/workspace';
import { TSuccessErrorHook } from '@/types/generic';
import { TWorkloadPreference } from '@/types/insights';
import { noop } from 'lodash';
import { handleApiCall } from '@/services/api';

const GET_WORKLOADS_PREFRENCES_KEY = 'get-workloads-prefrences';
export function useWorkloadPref(
  {
    enabled = true,
    onError = noop,
    onSuccess = noop,
  }: TSuccessErrorHook<Array<TWorkloadPreference>> = { enabled: true },
) {
  const { workspaceId } = useContext(UserContext);
  return useQuery({
    queryKey: [GET_WORKLOADS_PREFRENCES_KEY, workspaceId],
    queryFn: async () =>
      handleApiCall(
        getWorkloadsPreferences(workspaceId ?? ''),
        onSuccess,
        onError,
      ),
    enabled: !!workspaceId && enabled,
  });
}
