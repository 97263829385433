import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { getMemberCountByStatus } from '@/services/api/workspace/resources';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { handleApiCall } from '@/services/api';

export const GET_MEMBERS_COUNT_BY_STATUS_QUERY = 'get-members-count-by-status';
export default function useResourceCountByStatusQuery({
  query,
  enabled,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<{
  resourceActiveCount?: number;
  resourceHiddenCount?: number;
}> & {
  query?: string;
}) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  return useQuery({
    queryKey: [GET_MEMBERS_COUNT_BY_STATUS_QUERY, workspaceId, query] as [
      string,
      string,
      string | undefined,
    ],
    queryFn: ({ queryKey: [_k, ws, q] }) => {
      if (ws) {
        return handleApiCall(
          getMemberCountByStatus({ q, workspaceId: ws }),
          onSuccess,
          (error: TResponseError) => {
            onError(error);
            addNotification({
              type: 'error',
              title: t('errors:generic.title') as string,
              description:
                error?.message ?? (t('errors:generic.description') as string),
            });
          },
        );
      }
    },
    enabled: enabled && !!workspaceId,
  });
}
