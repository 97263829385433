import { useContext, useMemo } from 'react';

import { t } from 'i18next';

import { UserContext } from '@/contexts/UserContext';

import { useBlockContext } from './Context';
import styles from './styles.module.css';

import { SharedAppContext } from '@/shared-app/context/SharedAppContext';

export default function BlockWrapContent() {
  const { layoutIsExpanded } = useContext(SharedAppContext);
  const { workspaceMode } = useContext(UserContext);
  const { lengthWeeks, resourceName, block } = useBlockContext();

  const canShowResource = useMemo(() => {
    return !block.note || lengthWeeks > 2;
  }, [block.note, lengthWeeks]);

  return (
    <div className={styles.content}>
      {lengthWeeks >= 1 || layoutIsExpanded ? (
        <span className={styles.projectNameWrapper}>
          {/* {avatar && (
            <Avatar image={avatar?.ref} name={resourceName} size={'xsmall'} />
          )} */}
          <strong className={styles.projectName} title={resourceName}>
            {canShowResource && (
              <>
                {resourceName}
                {!layoutIsExpanded && ' · '}
              </>
            )}
            {block?.note && (
              <>
                {layoutIsExpanded && canShowResource && ' · '}
                <span>{block.note}</span>
                {`${!layoutIsExpanded ? ' · ' : ''}`}
              </>
            )}
          </strong>
        </span>
      ) : null}{' '}
      <strong>{block.allocation}</strong>{' '}
      {layoutIsExpanded
        ? lengthWeeks < 1
          ? block.allocation === 1
            ? t(`timeline:blockAllocationSingularShort${workspaceMode}`)
            : t(`timeline:blockAllocationPluralShort${workspaceMode}`)
          : block.allocation === 1
            ? t(`timeline:blockAllocationSingular${workspaceMode}`)
            : t(`timeline:blockAllocationPlural${workspaceMode}`)
        : lengthWeeks < 1
          ? t(`timeline:blockAllocationCompactShort${workspaceMode}`)
          : t(`timeline:blockAllocationCompact${workspaceMode}`)}
    </div>
  );
}
