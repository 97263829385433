import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { getHiddenProject } from '@/services/api/workspace/projects';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TTimelineHiddenProject } from '@/types/timeline';
import { handleApiCall } from '@/services/api';

export const HIDDEN_PROJECTS_QUERY = 'get-hidden-project';
export default function useHiddenProjectQuery({
  enabled = true,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<
  { hidden: TTimelineHiddenProject[]; id: string; count: number }[]
>) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  return useQuery({
    queryKey: [HIDDEN_PROJECTS_QUERY, workspaceId],
    queryFn: () =>
      handleApiCall(
        getHiddenProject({ workspaceId }),
        onSuccess,
        (error: TResponseError) => {
          onError(error);
          addNotification({
            type: 'error',
            title: t('errors:generic.title') as string,
            description:
              error?.message ?? (t('errors:generic.description') as string),
          });
        },
      ),
    enabled: enabled && !!workspaceId,
  });
}
