import { useContext } from 'react';

import { WORKSPACE_ASSIGN_QUERY_KEY } from '@hooks/workspace/resources/useResourceWorkspaceAssigned';
import { RESOURCES_QUERY_KEY } from '@hooks/workspace/resources/useResourcesQuery';
import { TIMELINE_RESOURCE_QUERY_KEY } from '@hooks/workspace/resources/useTimelineResourceQuery';
import type { InfiniteData } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { differenceInWeeks } from 'date-fns';
import { cloneDeep, find } from 'lodash';

import { UserContext } from '@/contexts/UserContext';
import { INSIGHT_PART_QUERY_KEY } from '@/hooks/insights/useInsightsPartQuery';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@/hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { updateAllocation } from '@/services/api/workspace/resources';
import type { PlannException } from '@/types/base-responses';
import { ALLOCATION_EVENT_OPERATION_TYPE, PROJECT_STATUS } from '@/types/enums';
import { TPagedQuery } from '@/types/generic';
import {
  TAllocationEvent,
  TProjectListWithResources,
  TResourceItemList,
  TTimelineAllocationResponse,
} from '@/types/timeline';

import { USAGE_INFO_QUERY_KEY } from '../useUsageInfo';

type Props = {
  resourceId: string;
  projectId: string;
  events: TAllocationEvent[];
  shouldInvalidate?: boolean;
  status: PROJECT_STATUS;
};

type UseAllocationProps = {
  onError?: (error: Error | PlannException) => void;
};

export default function useAllocation({ onError }: UseAllocationProps = {}) {
  const queryClient = useQueryClient();
  const { workspaceId } = useContext(UserContext);
  // const { addNotification } = useContext(NotificationsContext);

  return useMutation<
    TTimelineAllocationResponse | undefined,
    Error,
    Props,
    InfiniteData<TPagedQuery<TProjectListWithResources<TResourceItemList>>>
  >({
    mutationFn: async ({
      resourceId,
      projectId,
      events,
    }: Props): Promise<TTimelineAllocationResponse | undefined> => {
      return await updateAllocation({
        workspaceId,
        resourceId,
        projectId,
        events,
      });
    },
    onSuccess: (data) => {
      if (data) {
        queryClient.invalidateQueries({ queryKey: [INSIGHT_PART_QUERY_KEY] });
        queryClient.invalidateQueries({
          queryKey: [TIMELINE_RESOURCE_QUERY_KEY],
        });
        queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
        queryClient.invalidateQueries({
          queryKey: [WORKSPACE_ASSIGN_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [USAGE_INFO_QUERY_KEY, workspaceId],
        });
      }
    },
    onMutate: ({ events, projectId, resourceId }) => {
      const oldData = queryClient.getQueryData<
        InfiniteData<TPagedQuery<TProjectListWithResources<TResourceItemList>>>
      >([PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId]);
      const currentProjectsDataPaged = cloneDeep(oldData);

      const currentProjectsPage = currentProjectsDataPaged?.pages?.find(
        (page) => page.results.some((project) => project.id === projectId),
      );

      const currentProject = find(currentProjectsPage?.results, {
        id: projectId,
      });

      const currentResource = find(currentProject?.resources ?? [], {
        id: resourceId,
      });

      if (currentResource) {
        const previousEvents = currentResource?.timeblocks;
        const updatedEvents = events?.reduce((acc, event) => {
          if (event.operation !== ALLOCATION_EVENT_OPERATION_TYPE.INSERT) {
            const currentEventIndex = acc.findIndex(
              (e) => e.id === event.allocationId,
            );
            if (event?.operation === ALLOCATION_EVENT_OPERATION_TYPE.DELETE) {
              return acc.filter((_, index) => index !== currentEventIndex);
            } else {
              const updatedEvent = {
                ...acc[currentEventIndex],
                start: new Date(`${event.startDate}T00:00`),
                end: new Date(`${event.endDate}T23:59`),
                allocation: event.allocation,
              };
              return acc.map((item, index) =>
                index === currentEventIndex ? updatedEvent : item,
              );
            }
          } else {
            return [
              ...acc,
              {
                id: event.allocationId,
                start: new Date(`${event.startDate}T00:00`),
                end: new Date(`${event.endDate}T23:59`),
                allocation: event.allocation,
              },
            ];
          }
        }, previousEvents || []);
        currentResource.timeblocks = updatedEvents;
        currentResource.totalAllocation = currentResource.timeblocks.reduce(
          (acc, { allocation = 0, start, end }) =>
            acc + allocation * (differenceInWeeks(end, start) + 1),
          0,
        );
        currentProject!.totalAllocation =
          currentProject?.resources.reduce(
            (acc, { totalAllocation = 0 }) => acc + totalAllocation,
            0,
          ) ?? 0;

        queryClient.setQueryData<
          InfiniteData<
            TPagedQuery<TProjectListWithResources<TResourceItemList>>
          >
        >(
          [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId],
          currentProjectsDataPaged,
        );
      }
      return oldData;
    },
    onError: (error: Error, _variable, context) => {
      onError?.(error);
      queryClient.setQueryData<
        InfiniteData<TPagedQuery<TProjectListWithResources<TResourceItemList>>>
      >([PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId], context);
    },
  });
}
