import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteAccessToken } from '@/services/api/workspace/workspace';

import { ACCOUNT_QUERY_KEY } from '../account/useAccountQuery';

type Props = {
  workspaceId: string;
  accessTokenId: string;
};
export default function useRevokeAccessToken() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ workspaceId, accessTokenId }: Props) => {
      await deleteAccessToken(workspaceId, accessTokenId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [ACCOUNT_QUERY_KEY]});
    },
  });
}
