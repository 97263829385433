import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { getAccount } from '@/services/api/account';

export const ACCOUNT_QUERY_KEY = 'get-account';
export default function useAccountQuery({ enabled }: { enabled: boolean }) {
  return useQuery({
    queryKey: [ACCOUNT_QUERY_KEY],
    queryFn: () => getAccount(),
    enabled,
    retry: false,
  });
}
