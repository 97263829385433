import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { handleApiCall } from '@/services/api';
import { getInsightsPart } from '@/services/api/workspace/workspace';
import { INSIGHTS_TYPE } from '@/types/enums';
import { TSuccessErrorHook } from '@/types/generic';
import { TInsights, TInsightsPartKey } from '@/types/insights';

export const INSIGHT_PART_QUERY_KEY = 'get-insights-part';
export default function useInsightsPartQuery<T extends INSIGHTS_TYPE>({
  insightsType,
  min,
  max,
  enabled = true,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<TInsights[TInsightsPartKey<T>] | undefined> & {
  insightsType: T;
  min?: number;
  max?: number;
}) {
  const { workspace } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);
  const key: Array<string | number | undefined> = [
    INSIGHT_PART_QUERY_KEY,
    insightsType,
    workspace?.id,
  ];
  if (min !== undefined && min !== null) key.push(min);
  if (max !== undefined && max !== null) key.push(max);

  return useQuery({
    queryKey: key,
    queryFn: () =>
      handleApiCall<TInsights[TInsightsPartKey<T>] | undefined>(
        getInsightsPart<T>({
          insightsType,
          min,
          max,
          workspaceId: workspace?.id,
        }),
        onSuccess,
        (error) => {
          onError(error);
          addNotification({
            type: 'error',
            title: t('errors:generic.title') as string,
            description:
              error?.message ?? (t('errors:generic.description') as string),
          });
        },
      ),
    enabled: enabled && !!workspace?.id && !!insightsType,
  });
}
