import { useContext, useEffect, useMemo } from 'react';

import { getAuth } from '@firebase/auth';
import { Dialog, DialogPanel } from '@headlessui/react';
import {
  IconAdjustmentsAlt,
  IconApiApp,
  IconBuilding,
  IconCreditCard,
  IconMap,
  IconNotification,
  IconSettings,
  IconUserCircle,
  IconUsers,
  IconX,
} from '@tabler/icons-react';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { useHotkeysContext } from 'react-hotkeys-hook';

import { UserContext } from '@/contexts/UserContext';
import { truncate } from '@/services/helpers';
import { GLOBAL_SCOPE } from '@/types/constants';
import { USER_ROLE, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';

import Button from '@/components/Button';

import styles from './styles.module.css';
import { SectionGroup } from '../../SectionGroup';

export enum ContentPages {
  PROFILE_INFO = 'MY_ACCOUNT',
  PREFERENCES = 'PREFERENCES',
  SETTINGS = 'WORKSPACE_SETTINGS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  RESOURCE_MANAGE = 'RESOURCE_MANAGE',
  PLANS = 'PLANS',
  GENERAL = 'GENERAL',
  BILLING_INFO = 'BILLING_INFO',
  API = 'API',
}

type ModalProfilePreferencesProps = {
  onClose?: () => void;
  isOpen: boolean;
  selectedPageId?: ContentPages;
  selectedPage: JSX.Element;
  setSelectedPageId?: React.Dispatch<
    React.SetStateAction<ContentPages | undefined>
  >;
};

export default function ModalUserProfile({
  setSelectedPageId,
  selectedPageId,
  selectedPage,
  isOpen,
  onClose,
}: ModalProfilePreferencesProps) {
  const auth = getAuth();
  const isMdDevice = useMediaQuery('(min-width: 992px)');
  const { account, workspaceId } = useContext(UserContext);
  const workspace = useMemo(() => {
    return account?.workspaces.find((w) => w.id === workspaceId);
  }, [account?.workspaces, workspaceId]);

  const { disableScope, enableScope } = useHotkeysContext();

  useEffect(() => {
    if (isOpen) {
      disableScope(GLOBAL_SCOPE);
      enableScope('ModalUserProfile-scope');
    } else {
      enableScope(GLOBAL_SCOPE);
      disableScope('ModalUserProfile-scope');
    }
    return () => {
      enableScope(GLOBAL_SCOPE);
      disableScope('ModalUserProfile-scope');
    };
  }, [disableScope, enableScope, isOpen]);

  const accountItems = useMemo(
    () =>
      [
        {
          itemId: ContentPages.PROFILE_INFO,
          icon: <IconUserCircle width={20} height={20} />,
          title: t('common:profileModal.myAccount.title'),
        },
        {
          itemId: ContentPages.PREFERENCES,
          icon: <IconAdjustmentsAlt width={20} height={20} />,
          title: t('common:profileModal.preferences.title'),
        },
        {
          itemId: ContentPages.NOTIFICATIONS,
          icon: <IconNotification width={20} height={20} />,
          title: t('common:profileModal.notifications.title'),
        },
        {
          itemId: ContentPages.API,
          icon: <IconApiApp width={20} height={20} />,
          title: 'API',
        },
      ].map((obj) => {
        return {
          ...obj,
          onClick: setSelectedPageId as (groupId: string) => void,
          isActive: selectedPageId === obj.itemId,
        };
      }),
    [selectedPageId, setSelectedPageId],
  );

  const workspaceSpecificItems = useMemo(
    () =>
      [
        {
          itemId: ContentPages.PLANS,
          icon: <IconMap width={20} height={20} />,
          title: t('common:profileModal.plans.title'),
        },
        ...(workspace?.role === USER_ROLE.OWNER
          ? [
              {
                itemId: ContentPages.GENERAL,
                icon: <IconBuilding width={20} height={20} />,
                title: 'General',
                onClick: setSelectedPageId,
                isActive: selectedPageId === ContentPages.GENERAL,
              },
              {
                itemId: ContentPages.SETTINGS,
                icon: <IconSettings width={20} height={20} />,
                title: 'Settings',
                onClick: setSelectedPageId,
                isActive: selectedPageId === ContentPages.SETTINGS,
              },
              {
                itemId: ContentPages.RESOURCE_MANAGE,
                icon: <IconUsers width={20} height={20} />,
                title: 'Members',
                onClick: setSelectedPageId,
                isActive: selectedPageId === ContentPages.RESOURCE_MANAGE,
              },
              {
                itemId: ContentPages.BILLING_INFO,
                icon: <IconCreditCard width={20} height={20} />,
                title: 'Billing',
                onClick: setSelectedPageId,
                isActive: selectedPageId === ContentPages.BILLING_INFO,
              },
            ]
          : workspace?.permission === WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE
            ? [
                {
                  itemId: ContentPages.SETTINGS,
                  icon: <IconSettings width={20} height={20} />,
                  title: 'Settings',
                  onClick: setSelectedPageId,
                  isActive: selectedPageId === ContentPages.SETTINGS,
                },
                {
                  itemId: ContentPages.RESOURCE_MANAGE,
                  icon: <IconUsers width={20} height={20} />,
                  title: 'Members',
                  onClick: setSelectedPageId,
                  isActive: selectedPageId === ContentPages.RESOURCE_MANAGE,
                },
              ]
            : []),
      ].map((obj) => {
        return {
          ...obj,
          onClick: setSelectedPageId as (groupId: string) => void,
          isActive: selectedPageId === obj.itemId,
        };
      }),
    [selectedPageId, setSelectedPageId, workspace?.permission, workspace?.role],
  );

  const handleOnClose = () => {
    onClose?.();
  };

  const email = useMemo(() => {
    if (auth.currentUser?.email) {
      if (auth.currentUser?.email.length > 20) {
        const lastDotIndex = auth.currentUser.email.lastIndexOf('.') - 1;
        const eml = auth.currentUser.email.slice(0, lastDotIndex);
        const tr = truncate(
          eml,
          17,
          `...${auth.currentUser?.email.slice(lastDotIndex)}`,
        );
        return tr;
      }
      return auth.currentUser?.email;
    }
  }, [auth.currentUser?.email]);

  return (
    <>
      {isOpen && (
        <Dialog
          key={'modal-user-profile'}
          className={styles.container}
          open={isOpen}
          onClose={handleOnClose}
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          static
        >
          <div className={styles.overlay} />
          <DialogPanel
            as={motion.div}
            initial={{ opacity: 0, scale: 0.97 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.97 }}
            className={styles.panel}
          >
            <div className={styles.sidebar}>
              {!isMdDevice && (
                <div className={styles.sidebarHeader}>
                  <h3>Preferences</h3>{' '}
                  <Button
                    icon={IconX}
                    variant="ghost"
                    size="medium"
                    onClick={handleOnClose}
                  />
                </div>
              )}
              {isMdDevice && (
                <SectionGroup
                  title={email}
                  tooltip={auth.currentUser?.email ?? ''}
                  items={accountItems}
                />
              )}
              <SectionGroup
                title={
                  isMdDevice
                    ? t('common:profileModal.workspaceSettings.titleSection')
                    : ''
                }
                items={
                  isMdDevice
                    ? [...workspaceSpecificItems]
                    : [...accountItems, ...workspaceSpecificItems]
                }
              />
            </div>

            <div className={styles.body}>{selectedPage}</div>
          </DialogPanel>
        </Dialog>
      )}
    </>
  );
}
