import { useMutation, useQueryClient } from '@tanstack/react-query';

import { INSIGHT_PART_QUERY_KEY } from '@/hooks/insights/useInsightsPartQuery';
import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import { hideMemeber } from '@/services/api/workspace/resources';
import { TSuccessErrorHook } from '@/types/generic';

import { GET_MEMBERS_COUNT_BY_STATUS_QUERY } from './useResourceCountByStatusQuery';
import { RESOURCES_QUERY_KEY } from './useResourcesQuery';
import { SEARCH_MANAGER_RESOURCES_KEY } from './useSearchManagerResourcesQuery';
import { TIMELINE_RESOURCE_QUERY_KEY } from './useTimelineResourceQuery';

const HIDE_MEMBER_KEY = 'hide-member';
export default function useHideMember({ onSuccess }: TSuccessErrorHook<void>) {
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  return useMutation({
    mutationKey: [HIDE_MEMBER_KEY],
    mutationFn: async ({
      workspaceId,
      resourceId,
      revokeAccess = false,
    }: {
      workspaceId: string;
      resourceId: string;
      revokeAccess?: boolean;
    }) => await hideMemeber({ workspaceId, resourceId, revokeAccess }),

    onSuccess: (_data, { workspaceId }) => {
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [TIMELINE_RESOURCE_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [SEARCH_MANAGER_RESOURCES_KEY],
      });
      queryClient.invalidateQueries({ queryKey: [INSIGHT_PART_QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [GET_MEMBERS_COUNT_BY_STATUS_QUERY],
      });
      trackEvent(ANALYTICS_EVENTS.PERSON_HIDDEN, workspaceId);
      onSuccess?.();
    },
  });
}
