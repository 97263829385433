import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import Portal from '@components/Portal';
import { IconPlus, IconSearch } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { t } from 'i18next';
import { noop } from 'lodash';
import { Item, Menu, Separator, contextMenu } from 'react-contexify-moze';
import type { TriggerEvent } from 'react-contexify-moze';
import { ClipLoader } from 'react-spinners';
import { useDebounce } from 'use-hooks';

import { UIContext } from '@/contexts/UIContext';
import useProjectsQuery, {
  SEARCH_PROJECTS_KEY,
} from '@/hooks/workspace/projects/useProjectsQuery';
import useAssignProjectToResource from '@/hooks/workspace/resources/useAssignProjectToResource';
import { getCssVariable } from '@/services/helpers';

import styles from './styles.module.css';
import Input from '../Input';
import ModalAddNewProject from '../Modals/ModalAddNewProject';
import ProjectEmoji from '../ProjectEmoji';

export default function AssignProjectButtonDropdown({ id }: { id: string }) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuPositionRef = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);
  const [queryIsEnabled, setQueryIsEnabled] = useState(false);
  const {
    data: allProjects,
    isLoading,
    isFetched,
  } = useProjectsQuery({
    query: debouncedValue,
    enabled: queryIsEnabled,
    resourceId: id,
    onError: noop,
  });

  const MENU_ID = `assign-project-context-menu-${id}`;
  function getMenuPosition() {
    const { bottom } = triggerRef?.current?.getBoundingClientRect() ?? {
      bottom: 0,
    };
    menuPositionRef.current = { x: 8, y: bottom + 8 };
    return menuPositionRef.current;
  }

  const eventRef = useRef<TriggerEvent | null>(null);

  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setQueryIsEnabled(true);
      eventRef.current = e;
      Boolean(isFetched) &&
        contextMenu?.show({
          id: MENU_ID,
          event: e,
          position: getMenuPosition(),
        });
    },
    [MENU_ID, isFetched],
  );

  useEffect(() => {
    if (isFetched && eventRef.current) {
      contextMenu?.show({
        id: MENU_ID,
        event: eventRef?.current as TriggerEvent,
        position: getMenuPosition(),
      });
    }
  }, [MENU_ID, isFetched]);

  const { mutate, isPending: isLoadingMutate } = useAssignProjectToResource();
  const queryClient = useQueryClient();
  const [assigningProjectId, setAssigningProjectId] = useState<string | null>(
    null,
  );

  const onProjectClickFn = useCallback(
    (projectId: string) => {
      setAssigningProjectId(projectId);
      mutate(
        { resourceId: id, projectId },
        {
          onSuccess: () => {
            contextMenu.hideAll();
            setAssigningProjectId(null);
            queryClient.invalidateQueries({ queryKey: [SEARCH_PROJECTS_KEY] });
          },
        },
      );
    },
    [id, mutate, queryClient],
  );
  const ref = useRef<HTMLInputElement>(null);

  const onChangeFn = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);
  const { layoutIsExpanded } = useContext(UIContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <button
        ref={triggerRef}
        onClick={onClickFn}
        className={classNames(styles.container, {
          [styles.isExpanded]: layoutIsExpanded,
        })}
      >
        <IconPlus size={16} /> {t('timeline:assignProject')}
      </button>
      <Portal>
        <Menu
          animation={false}
          className={classNames(styles.menu, 'top-triangle')}
          id={MENU_ID}
          onVisibilityChange={(isVisible) => {
            setValue('');
            if (!isVisible) {
              setQueryIsEnabled(false);
            }
          }}
        >
          <Item
            preventCloseOnKeyDown
            className="unstyled-item"
            style={{ opacity: 1 }}
            disabled
            closeOnClick={false}
          >
            <Input
              ref={ref}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              LeadingIcon={<IconSearch size={20} />}
              isDark
              name="input"
              placeholder={`${t('common:search')}...`}
              value={value}
              onChange={onChangeFn}
            />
          </Item>
          <Separator />

          {allProjects?.length ? (
            allProjects?.map((project, index) => (
              <Item
                key={index}
                tabIndex={index}
                closeOnClick={false}
                onClick={() => onProjectClickFn(project.id)}
                // keyMatcher={(e: KeyboardEvent): boolean => {
                //   return e.key === (index + 1).toString();
                // }}
              >
                {isLoadingMutate && assigningProjectId === project.id && (
                  <ClipLoader
                    color={getCssVariable('--color-neutral-500')}
                    cssOverride={{
                      borderWidth: '2px',
                      marginRight: 4,
                    }}
                    size={16}
                    speedMultiplier={0.8}
                  />
                )}
                <div className={styles.projectItem}>
                  <ProjectEmoji
                    color={project.color}
                    emojiSize={12}
                    emoji={project.emoji}
                    size={20}
                  />
                  <span>{project.name}</span>
                </div>
                {/* <RightSlot>{index + 1}</RightSlot> */}
              </Item>
            ))
          ) : isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: '10px 0',
              }}
              className={styles.loader}
            >
              <ClipLoader
                color={getCssVariable('--color-neutral-500')}
                cssOverride={{
                  borderWidth: '2px',
                }}
                size={20}
                speedMultiplier={0.8}
              />
            </div>
          ) : (
            <Item disabled>{t('common:noProjectToAssign')}</Item>
          )}
          <Separator />
          <Item
            onClick={() => setIsModalOpen(true)}
            // keyMatcher={(e: KeyboardEvent): boolean => {
            //   e.preventDefault();
            //   return e.metaKey && e.key === 'n';
            // }}
          >
            <IconPlus style={{ marginRight: 8 }} size={20} />
            {t('common:addProjectContextMenuActions.addProject')}{' '}
            {/* <RightSlot>⌘N</RightSlot> */}
          </Item>
        </Menu>
      </Portal>
      {isModalOpen && (
        <ModalAddNewProject
          isOpen={isModalOpen}
          resourceId={id}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
}
