import { PropsWithChildren, useContext } from 'react';

import classNames from 'classnames';


import styles from './styles.module.css';

import { SharedAppContext } from '@/shared-app/context/SharedAppContext';

export default function ResourceRowContent({ children }: PropsWithChildren) {
  const { layoutIsExpanded } = useContext(SharedAppContext);

  return (
    <div
      style={{ zIndex: 1 }}
      className={classNames(styles.content, {
        [styles.isExpanded]: layoutIsExpanded,
      })}
    >
      {children}
    </div>
  );
}
