import { useCallback, useContext, useMemo, useRef, useState } from 'react';

import Avatar from '@components/Avatar';
import Button from '@components/Button';
import type { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import {
  IconChevronDown,
  IconChevronRight,
  IconDots,
  IconGripVertical,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { find } from 'lodash';
import { contextMenu } from 'react-contexify-moze';

import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import { getCssVariable } from '@/services/helpers';
import {
  RESOURCE_STATUS,
  USER_ROLE,
  WORKSPACE_MEMBER_PERMISSION,
} from '@/types/enums';
import { TAttachmentDataUrls } from '@/types/generic';
import { TUserWorkspace, TWorkspaceAccess } from '@/types/workspace';

import ResourceHandlerMenu from '@/components/MenuUtility/ResourceHanlderMenu';

import styles from './styles.module.css';

type Props = {
  id: string;
  isCompressed?: boolean;
  onCompress?: () => void;
  firstName: string;
  lastName: string;
  image?: TAttachmentDataUrls;
  capacity: number;
  isDraggableOverlay?: boolean;
  workspaceAccess?: TWorkspaceAccess;
  sortHandlerProps?: {
    listeners?: SyntheticListenerMap;
    activator: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
  };
};

export default function ResourceHeader({
  id,
  isCompressed,
  onCompress,
  isDraggableOverlay,
  capacity,
  firstName,
  lastName,
  image,
  workspaceAccess,
  sortHandlerProps,
}: Props) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuPositionRef = useRef<{ x: number; y: number } | undefined>(undefined);

  function getMenuPosition() {
    const { left, bottom, width } =
      triggerRef?.current?.getBoundingClientRect() ?? {
        left: 0,
        bottom: 0,
        width: 0,
      };
    menuPositionRef.current = { x: left - (160 - width), y: bottom + 8 };
    return menuPositionRef.current;
  }

  const MENU_ID = `team-context-menu-${firstName}-${lastName}`;

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (menuIsOpen) {
        contextMenu.hideAll();
        return;
      }
      contextMenu.show({
        id: MENU_ID,
        event: e,
        position: getMenuPosition(),
      });
    },
    [menuIsOpen, MENU_ID],
  );

  const { account, workspaceId } = useContext(UserContext);
  const workspace = find(account?.workspaces, {
    id: workspaceId,
  }) as TUserWorkspace;
  const { layoutIsExpanded } = useContext(UIContext);

  const isYou = useMemo(() => {
    return workspaceAccess?.email === account?.email;
  }, [account?.email, workspaceAccess?.email]);

  const canDelete = useMemo(() => {
    return (
      !isYou &&
      (workspaceAccess?.role !== USER_ROLE.OWNER ||
        workspace.role === USER_ROLE.OWNER)
    );
  }, [isYou, workspace.role, workspaceAccess?.role]);

  return (
    <>
      <div
        aria-hidden
        className={classNames(styles.container, {
          [styles.isExpanded]: layoutIsExpanded,
          [styles.menuIsOpen]: menuIsOpen,
        })}
        onClick={onCompress}
      >
        <div className={styles.content}>
          {sortHandlerProps?.activator && (
            <div
              className={classNames(styles.sortHandler, {
                [styles.sortHandlerHidden]:
                  workspace?.permission ===
                  WORKSPACE_MEMBER_PERMISSION.READ_ONLY,
              })}
              {...sortHandlerProps?.listeners}
              {...sortHandlerProps?.attributes}
              ref={sortHandlerProps?.activator}
            >
              <IconGripVertical
                size={16}
                color={getCssVariable('--color-neutral-500')}
              />
            </div>
          )}
          {isDraggableOverlay && (
            <div className={styles.sortHandler}>
              <IconGripVertical size={16} />
            </div>
          )}
          <span className={styles.collapse}>
            {!isCompressed ? (
              <IconChevronDown size={16} />
            ) : (
              <IconChevronRight size={16} />
            )}
          </span>
          <div className={styles.avatar}>
            <Avatar
              image={image?.ref}
              name={`${firstName} ${lastName}`}
              size="medium"
            />
          </div>
          <div className={styles.desktop}>
            <p className={styles.resourceName}>
              {firstName} {lastName}
            </p>
            {workspace?.permission ===
              WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE && (
              <Button
                ref={triggerRef}
                icon={IconDots}
                variant="ghost"
                size="small"
                onClick={onClickFn}
              />
            )}
          </div>
        </div>
      </div>
      <ResourceHandlerMenu
        id={id}
        firstName={firstName}
        lastName={lastName}
        menuId={MENU_ID}
        isYourself={isYou}
        capacity={capacity}
        canDelete={canDelete}
        image={image}
        workspaceAccess={workspaceAccess}
        status={RESOURCE_STATUS.ACTIVE}
        onVisibilityChange={(isVisible: boolean) => setMenuIsOpen(isVisible)}
      />
    </>
  );
}
