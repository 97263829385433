export enum ROUTES {
  ROOT = '/',
  DASHBOARD = '/:workspaceId',
  PROJECT = '/:workspaceId/projects/',
  SIGN_IN = '/sign-in',
  AUTH = '/auth',
  PASSWORD_RECOVER = '/password-recover',
  PASSWORD_RESET = '/password-reset',
  SIGN_UP = '/sign-up',
  EMAIL_VERIFICATION = '/email-verification',
  WORKSPACE_CREATE = '/onboarding/workspace-create',
  WORKSPACE_SET_TIME_MODE = '/onboarding/:workspaceId/time-mode',
  ADD_MEMBER = '/onboarding/:workspaceId/add-member',
  ACCEPT_INVITATION = '/accept',
  ON_BOARDING = '/onboarding',
  VALIDATE_EMAIL = '/validate-email',
  VALIDATE_AND_CHANGE_EMAIL = '/validate-and-change-email',
  RECOVER_EMAIL = '/recover-email',
  SHARED = '/s',
}

export enum PUBLIC_ROUTES {
  ROOT = '/s/:token',
  FORBIDDEN = '/forbidden',
  GENERIC_ERROR = '/error',
}
