import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { handleApiCall } from '@/services/api';
import { getProjects } from '@/services/api/workspace/projects';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TProject } from '@/types/timeline';

export const SEARCH_PROJECTS_KEY = 'get-projects';
export default function useProjectsQuery({
  query,
  enabled,
  resourceId,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<TProject[]> & {
  query: string;
  resourceId: string;
}) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  return useQuery({
    queryKey: [SEARCH_PROJECTS_KEY, workspaceId, query, resourceId],
    queryFn: () =>
      handleApiCall(
        getProjects({ resourceId, query, workspaceId }),
        onSuccess,
        (error: TResponseError) => {
          onError(error);
          addNotification({
            type: 'error',
            title: t('errors:generic.title') as string,
            description:
              error?.message ?? (t('errors:generic.description') as string),
          });
        },
      ),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
}
