import { useContext } from 'react';

import type { QueryKey } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { updateAccountRole } from '@/services/api/account';
import { USER_ROLE } from '@/types/enums';
import { TResourceSearchResult } from '@/types/timeline';

import { CAN_DELETE_ACCOUNT_KEY } from './useCanDeleteAccount';
import { SEARCH_MANAGER_RESOURCES_KEY } from '../workspace/resources/useSearchManagerResourcesQuery';

export type UserAccountRoleUpdate = {
  workspaceId: string;
  role: USER_ROLE;
  accountId: string;
};

export default function useUpdateAccountRole({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
} = {}) {
  const queryClient = useQueryClient();

  const { addNotification } = useContext(NotificationsContext);
  const { account } = useContext(UserContext);
  return useMutation<
    void,
    Error & { message?: string },
    UserAccountRoleUpdate | UserAccountRoleUpdate[],
    [QueryKey, TResourceSearchResult[] | undefined][][]
  >({
    mutationFn: async (
      data: UserAccountRoleUpdate | UserAccountRoleUpdate[],
    ) => {
      if (!(data instanceof Array)) data = [data];
      await Promise.all(data.map((d) => updateAccountRole(d)));
    },
    // onMutate: async (data) => {
    //   if (!(data instanceof Array)) data = [data];
    //   const oldDatas: [QueryKey, TResourceSearchResult[] | undefined][][] =
    //     [];
    //   data.forEach(({ workspaceId, role, accountId }) => {
    //     const key = [SEARCH_MANAGER_RESOURCES_KEY, workspaceId];

    //     const oldDt =
    //       queryClient.getQueriesData<TResourceSearchResult[]>(key);
    //     oldDt.forEach(([queryKey, oldData]) => {
    //       if (!oldData) return;
    //       const newData = cloneDeep(oldData);
    //       const oldAccount = newData.find((nd) => nd.accountId === accountId);
    //       if (!oldAccount) return;
    //       oldAccount.role = role;
    //       if (role === USER_ROLE.OWNER)
    //         oldAccount.permission =
    //           WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE;
    //       queryClient.setQueryData(queryKey, newData);
    //       oldDatas.push(oldDt);
    //     });
    //   });
    //   return oldDatas;
    // },
    onSuccess: async (_data) => {
      queryClient.invalidateQueries({
        queryKey: [CAN_DELETE_ACCOUNT_KEY, account?.email],
      });
      queryClient.invalidateQueries({
        queryKey: [SEARCH_MANAGER_RESOURCES_KEY],
      });
      onSuccess?.();
    },
    onError: (error, _variables, oldDatas) => {
      oldDatas?.forEach((d) => {
        d.forEach(([queryKey, oldData]) => {
          queryClient.setQueryData(queryKey, oldData);
        });
      });
      onError?.(error);
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
