import { useContext } from 'react';

import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import { UserContext } from '@/contexts/UserContext';
import { http } from '@/services/api';
import { normalizeTransactionList } from '@/services/normalizer';
import { BaseResponse } from '@/types/base-responses';
import { Dto } from '@/types/generic';
import { TTransactionsList } from '@/types/invoices';

type Props = {
  enabled?: boolean;
};
const PAGE_SIZE = 15;
export const GET_INVOICES_KEY = 'get-invoices';
export const useGetInvoices = ({ enabled = true }: Props) => {
  const { workspace } = useContext(UserContext);
  return useInfiniteQuery<
    TTransactionsList | null,
    Error,
    InfiniteData<TTransactionsList | null>,
    [string, string | undefined],
    string | null
  >({
    initialPageParam: null,
    queryKey: [GET_INVOICES_KEY, workspace?.id],
    queryFn: async ({ pageParam, queryKey: [_, ws] }) => {
      if (!ws) return null;
      const response = await http.get<BaseResponse<TTransactionsList>>(
        `/workspaces/${ws}/invoices?pageSize=${PAGE_SIZE}${pageParam ? `&lastId=${pageParam}` : ''}`,
      );
      if (response.data.data?.transactions?.length) {
        response.data.data.transactions =
          response.data.data.transactions.filter(
            (transaction) =>
              transaction.status === 'completed' && !!transaction.invoiceNumber,
          );
      }
      return (
        response.data.data &&
        normalizeTransactionList(response.data.data as Dto<TTransactionsList>)
      );
    },
    enabled,
    getNextPageParam: (lastPage) =>
      lastPage?.hasNext ? (lastPage?.nextId ?? null) : null,
    getPreviousPageParam: (firstPage) => firstPage?.previousId ?? null,
  });
};
