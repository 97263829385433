import { useContext, createContext, RefObject } from 'react';

import { TResourceItemList } from '@/types/timeline';

export type MouseEventRef = {
  mouseDownX?: number;
  mouseMoveX?: number;
  newInterval?: Interval;
  resourceId?: string;
};

type Interval = {
  start: Date;
  end: Date;
};

const Context = createContext<{
  resource?: TResourceItemList;
  mouseEventRef: RefObject<MouseEventRef>;
}>({
  mouseEventRef: { current: {} },
  resource: undefined,
});

export function useResourceRowContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'ResourceRowContext must be used within a ResourceRowProvider',
    );
  }
  return context;
}

export default Context;
