import React, { useCallback, useEffect, useRef, useState } from 'react';

import Button from '@components/Button';
import Section from '@components/Section';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';

import { useSubscription } from '@/contexts/UserContext';
import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import useUpdateWorkspace from '@/hooks/workspace/useUpdateWorkspace';
import { useWorkspaceFromContext } from '@/hooks/workspace/useWorkspaceFromContext';
import { WORKSPACE_MODE } from '@/types/enums';

import Input from '@/components/Input';
import ModalDeleteWorkspace from '@/components/Modals/ModalDeleteWorkspace';
import SelectDropdown from '@/components/SelectDropdown';

import RemoveSubAlert from './RemoveSubAlert';
import styles from './styles.module.css';

export type UpdateWorkspaceDataForm = {
  workspaceName: string;
};

export default function ModalWorkspaceSettingGeneral() {
  const ref = useRef<HTMLInputElement>(null);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UpdateWorkspaceDataForm>({ mode: 'onSubmit' });
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();
  const { workspace } = useWorkspaceFromContext();
  const { subscription } = useSubscription();
  const { mutate, isPending } = useUpdateWorkspace({
    onSuccess: () => queryClient.invalidateQueries(),
  });

  const [deleteWsModal, setDeleteWsModal] = useState<boolean>(false);

  useEffect(() => {
    setValue('workspaceName', workspace?.name ?? '');
  }, [setValue, workspace?.name]);

  const onSubmitFn = useCallback(
    (data: UpdateWorkspaceDataForm) => {
      mutate({
        workspaceName: data.workspaceName,
        workspaceId: workspace?.id ?? '',
      });
    },
    [mutate, workspace?.id],
  );

  const [mode, setMode] = useState<WORKSPACE_MODE>(
    workspace?.mode ?? WORKSPACE_MODE.DAYS,
  );

  const { mutate: updateMode } = useUpdateWorkspace({
    onSuccess: () => queryClient.invalidateQueries(),
  });

  const onHandleSelectChange = useCallback(
    (newMode: WORKSPACE_MODE) => {
      if (workspace?.id) {
        setMode(newMode);
        trackEvent(ANALYTICS_EVENTS.SWITCHED_TIME_MODE, workspace?.id, {
          TIME_MODE: newMode,
          TOUCH_POINT: 'SETTINGS',
        });
        updateMode({
          workspaceId: workspace?.id ?? '',
          mode: newMode,
        });
      }
    },
    [updateMode, trackEvent, workspace?.id],
  );

  return (
    <React.Fragment>
      <Section title={t('common:profileModal.workspaceSettings.title')}>
        <div className={styles.mainContainer}>
          <form onSubmit={handleSubmit(onSubmitFn)}>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('forms:required', {
                    field: t('forms:workspace.create.name.label'),
                  }),
                },
                maxLength: {
                  value: 180,
                  message: t('forms:maxLength', {
                    field: t('forms:workspace.create.name.label'),
                    count: 180,
                  }),
                },
              }}
              render={({ field: { value, name, onChange, onBlur } }) => {
                return (
                  <Input
                    required
                    ref={ref}
                    label={'Name'}
                    value={value}
                    placeholder={'E.g Acme'}
                    name={name}
                    error={errors?.workspaceName?.message as string}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={isPending}
                  />
                );
              }}
              name={'workspaceName'}
            />
            <p className={styles.textLabel}>
              {t('common:profileModal.workspaceSettings.description')}
            </p>
            <input type="submit" hidden />
            <div className={styles.submitButton}>
              <Button
                label={t(`forms:workspace.settings.submitBtn`)}
                type="submit"
                variant="outline"
                size="medium"
                isLoading={isPending}
              />
            </div>
          </form>
        </div>
      </Section>
      <Section title={t(`forms:workspace.settings.timeMode.sectionTitle`)}>
        <div className={styles.mainContainer}>
          <div className={styles.rowWrapper}>
            <div className={styles.rowLabelWrapper}>
              <div className={styles.rowLabel}>
                {t(`forms:workspace.settings.timeMode.label`)}
              </div>
              <div className={styles.rowDescription}>
                {t(`forms:workspace.settings.timeMode.subLabel`)}
              </div>
            </div>
            <div className={styles.modeChangeDropdown}>
              <SelectDropdown label={t(`common:${mode}PerWeek`)} align="end">
                <SelectDropdown.Item
                  label={t(`common:${WORKSPACE_MODE.DAYS}PerWeek`)}
                  subLabel="Works best with full-time people on your team"
                  checked={mode === WORKSPACE_MODE.DAYS}
                  onSelect={() => onHandleSelectChange(WORKSPACE_MODE.DAYS)}
                />
                <SelectDropdown.Item
                  label={t(`common:${WORKSPACE_MODE.HOURS}PerWeek`)}
                  subLabel="Works best with part-time people on your team"
                  checked={mode === WORKSPACE_MODE.HOURS}
                  onSelect={() => onHandleSelectChange(WORKSPACE_MODE.HOURS)}
                />
              </SelectDropdown>
            </div>
          </div>
        </div>
      </Section>
      <Section
        title={t('common:profileModal.workspaceSettings.dangerZone.title')}
      >
        <div className={styles.mainContainer}>
          <div className={styles.rowWrapper}>
            <div className={styles.rowLabelWrapper}>
              <div className={styles.rowLabel}>
                {t(`common:profileModal.workspaceSettings.dangerZone.label`)}
              </div>
              <div className={styles.rowDescription}>
                {t(
                  `common:profileModal.workspaceSettings.dangerZone.description`,
                )}
              </div>
            </div>
            <Button
              label={t(
                `common:profileModal.workspaceSettings.dangerZone.action`,
              )}
              variant="link"
              size="medium"
              onClick={() => setDeleteWsModal(true)}
              className={styles.dangerButton}
            />
          </div>
        </div>
      </Section>
      {deleteWsModal &&
        (subscription && !subscription.scheduledAction ? (
          <RemoveSubAlert
            isOpen={true}
            footer={undefined}
            onClose={() => setDeleteWsModal(false)}
          />
        ) : (
          <ModalDeleteWorkspace
            isOpen={deleteWsModal}
            onClose={() => setDeleteWsModal(false)}
          />
        ))}
    </React.Fragment>
  );
}
