import { useContext, useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { noop } from 'lodash';
import Modal from 'react-modal';
import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from 'react-router-dom';

import { PUBLIC_ROUTES } from '@/types/routes';

import { Error403 } from '@/screens/ErrorScreen/403';
import Error404 from '@/screens/ErrorScreen/404';
import { Error500 } from '@/screens/ErrorScreen/500';
import '../i18n';

import { SharedAppContext } from './context/SharedAppContext';
import Home from './screens/Home';

if (!import.meta.env.VITEST) {
  Modal.setAppElement('#root');
}

function CheckApp({ children }: { children: React.ReactNode }) {
  const { uuid, valid } = useContext(SharedAppContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!uuid || !valid) navigate(PUBLIC_ROUTES.FORBIDDEN);
  }, [navigate, uuid, valid]);
  return children;
}

export default function SharedApp() {
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

  const router = sentryCreateBrowserRouter([
    {
      path: '*',
      element: <Error404 />,
    },
    {
      path: PUBLIC_ROUTES.FORBIDDEN,
      element: <Error403 onClearError={noop} />,
    },
    {
      path: PUBLIC_ROUTES.GENERIC_ERROR,
      element: <Error500 />,
    },
    {
      path: PUBLIC_ROUTES.ROOT,
      element: (
        <CheckApp>
          <Home />
        </CheckApp>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}
