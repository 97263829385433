import { useContext, useMemo } from 'react';

import {
  endOfDay,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfDay,
} from 'date-fns';
import { filter, map } from 'lodash';

import { TTimeBlockRange } from '@/types/timeline';

import BlockWrap from './BlockWrap';
import { useResourceRowContext } from './Context';

import { TimelineProjectContext } from '@/shared-app/context/TimelineProjectContext';

export default function ResourceRowBlock() {
  const { resource } = useResourceRowContext();
  const { virtualizer, weeks } = useContext(TimelineProjectContext);
  const visibleWeeks = virtualizer?.getVirtualItems();
  const { projectInfo } = useContext(TimelineProjectContext);

  const visibleBlocks = useMemo(() => {
    const startDate = startOfDay(weeks[visibleWeeks[0].index]);
    const endDate = endOfDay(
      weeks[visibleWeeks[visibleWeeks.length - 1].index],
    );
    return filter(
      resource?.timeblocks,
      (block: TTimeBlockRange) =>
        isWithinInterval(block.start, {
          start: startDate,
          end: endDate,
        }) ||
        isWithinInterval(block.end, {
          start: startDate,
          end: endDate,
        }) ||
        (isBefore(block.start, startDate) && isAfter(block.end, endDate)),
    );
  }, [resource?.timeblocks, visibleWeeks, weeks]);

  return map(visibleBlocks, (block: TTimeBlockRange) => {
    return (
      resource && (
        <BlockWrap
          key={block.id}
          isActive={true}
          currentResource={resource}
          project={projectInfo}
          avatar={resource?.image}
          block={block}
        />
      )
    );
  });
}
