import { useLayoutEffect, useRef, useState } from 'react';

import { t } from 'i18next';

import useAccountConsentQuery from '@/hooks/account/useAccountConsentQuery';
import useAcceptConsent from '@/hooks/iubenda/useAcceptConsent';

import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import Modal from '@/components/Modals/Modal';

import SafeHtml from './SafeHtml';
import styles from './styles.module.css';

type Props = {
  onOpenChange?: (status: 'opened' | 'closed') => void;
};

export default function ModalTermsOfService({ onOpenChange }: Props) {
  // get the current user tos signature, if it exists and is valid close the modal
  // if not, open the modal and force the user to accept the tos
  // on accept, update the user tos signature and close the modal

  const { data: consent } = useAccountConsentQuery();

  const { mutate, isPending } = useAcceptConsent();

  const [accepted, setAccepted] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const onSubmitFn = () => {
    mutate(ref?.current?.innerHTML ?? '', {
      onSuccess: () => {
        onOpenChange?.('closed');
      },
    });
  };

  useLayoutEffect(() => {
    if (consent?.mustShow) onOpenChange?.('opened');
  }, [consent?.mustShow, onOpenChange]);

  return (
    consent?.mustShow && (
      <Modal
        modalKey="modal-terms-of-service"
        ref={ref}
        key={'modal-terms-of-service'}
        isOpen={consent?.mustShow ?? false}
        panelClassName={[styles.panel]}
        title={t('common:modals.tos.title')}
        footer={undefined}
      >
        <div className={styles.container}>
          <div className={styles.textContentWrapper}>
            <div className={styles.textContent}>
              <SafeHtml htmltext={consent.modalText ?? ''} />
            </div>
            <Checkbox
              value={accepted}
              name={'consent'}
              onChange={(e) => setAccepted(!!e)}
              label={
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('forms:signUp.terms.label', {
                      POLICY_URL: import.meta.env.VITE_PRIVACY_POLICY_URL,
                      TOS_URL: import.meta.env.VITE_TOS_URL,
                    }),
                  }}
                ></p>
              }
            />
            <Button
              label={t('common:modals.tos.primaryCtaLabel')}
              onClick={onSubmitFn}
              isLoading={isPending}
              disabled={!accepted}
              size="large"
            />
          </div>
        </div>
      </Modal>
    )
  );
}
