import { useMutation } from '@tanstack/react-query';
import { noop } from 'lodash';

import { createAccount } from '@/services/api/account';
import { Dto } from '@/types/generic';
import { TUserAccount, TUserAccountCreation } from '@/types/workspace';

type Props = Omit<
  Required<
    Dto<TUserAccountCreation & { token: string; consentTemplate: string }>
  >,
  'password'
>;

export default function useCreateAccount(opt: {
  onSuccess?: (
    data: (TUserAccount & { token?: string }) | undefined,
    variables: Required<Dto<Props>>,
    context: unknown,
  ) => unknown;
  onError?: typeof noop;
}) {
  return useMutation<
    (TUserAccount & { token?: string }) | undefined,
    Error,
    Props
  >({
    mutationFn: async (
      formData: Props,
    ): Promise<(TUserAccount & { tk?: string }) | undefined> => {
      return createAccount({ ...formData });
    },
    onSuccess: opt.onSuccess,
    onError: opt.onError,
  });
}
