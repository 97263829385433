import { QueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { TResources, TSharedProjectInfo } from '@/types/timeline';

import {
  PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY,
  TTimelineProjectListWithResourcesWithTimeblocksPage,
} from '../../useProjectListWithResourcesStatusQuery';

type ShareProjctProp = {
  projectId: string;
  queryClient: QueryClient;
  workspaceId?: string;
};

export function onProjectTimelineShareSuccess(
  data: TSharedProjectInfo | null,
  { projectId, queryClient, workspaceId }: ShareProjctProp,
) {
  const queryKey = [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId];
  const dt = cloneDeep(
    queryClient.getQueryData<TTimelineProjectListWithResourcesWithTimeblocksPage>(
      queryKey,
    ),
  );
  if (dt) {
    dt.pages.forEach((page) => {
      page.results
        .filter((p) => p.id === projectId)
        .forEach((p) => {
          p.shared = data ?? undefined;
        });
    });
  }
  queryClient.setQueryData(queryKey, dt, { updatedAt: Date.now() });
}

export function onResourceTimelineShareSuccess(
  data: TSharedProjectInfo | null,
  { projectId, queryClient, workspaceId }: ShareProjctProp,
) {
  const key = [RESOURCES_QUERY_KEY, workspaceId];
  const queryData = cloneDeep(queryClient.getQueryData<TResources[]>(key));
  if (queryData) {
    queryData.forEach((resources) => {
      resources.projects
        .filter((project) => project.id === projectId)
        .forEach((project) => {
          project.shared = data ?? undefined;
        });
    });
    queryClient.setQueryData(key, queryData);
  }
}

export async function onShareSuccess(
  data: TSharedProjectInfo | null,
  { projectId, queryClient, workspaceId }: ShareProjctProp,
) {
  onProjectTimelineShareSuccess(data, {
    projectId,
    queryClient,
    workspaceId,
  });
  onResourceTimelineShareSuccess(data, {
    projectId,
    queryClient,
    workspaceId,
  });
}

export const onRevokeSuccess = onShareSuccess.bind(null, null);
