import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { noop } from 'lodash';

import { UserContext } from '@/contexts/UserContext';
import { projectCountBySatatus } from '@/services/api/workspace/projects';
import { PROJECT_STATUS } from '@/types/enums';
import { TSuccessErrorHookWithParams } from '@/types/generic';
import { handleApiCall } from '@/services/api';

type TProjectCountByStatus = TSuccessErrorHookWithParams<
  { totalCount: number },
  {
    status: PROJECT_STATUS;
  }
>;
export const PROJECT_COUNT_BY_STATUS_QUERY_KEY =
  'PROJECT_COUNT_BY_STATUS_QUERY_KEY';
export default function useProjectCountByStatus({
  status,
  enabled = true,
  onError = noop,
  onSuccess = noop,
}: TProjectCountByStatus) {
  const { workspaceId } = useContext(UserContext);
  const KEY = [PROJECT_COUNT_BY_STATUS_QUERY_KEY, workspaceId, status];
  return useQuery({
    queryKey: KEY,
    queryFn: async ({ queryKey: [_, wsId, st] }) => {
      return handleApiCall(
        projectCountBySatatus({
          workspaceId: wsId!,
          status: st as PROJECT_STATUS,
        }),
        onSuccess,
        onError,
      );
    },
    enabled,
  });
}
