import { useCallback, useContext } from 'react';

import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';

import { titleCase } from '@/services/helpers';

import Button from '@/components/Button';

import styles from './styles.module.css';

import { TimelineProjectContext } from '@/shared-app/context/TimelineProjectContext';

export default function Header() {
  const {
    onNextFn: onNextProjectViewFn,
    onPrevFn: onPrevProjectViewFn,
    scrollTodayIntoView,
    projectInfo,
  } = useContext(TimelineProjectContext);

  const onNextLocalFn = useCallback(() => {
    onNextProjectViewFn();
  }, [onNextProjectViewFn]);

  const onPrevLocalFn = useCallback(() => {
    onPrevProjectViewFn();
  }, [onPrevProjectViewFn]);

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.82697 20.9308C8.81682 20.9984 10.0952 21 12 21C13.9048 21 15.1832 20.9984 16.173 20.9308C17.1359 20.8651 17.6048 20.7472 17.9134 20.6194C19.1386 20.1119 20.1119 19.1386 20.6194 17.9134C20.7472 17.6048 20.8651 17.1359 20.9308 16.173C20.9984 15.1832 21 13.9048 21 12C21 10.0952 20.9984 8.81682 20.9308 7.82697C20.8651 6.86406 20.7472 6.3952 20.6194 6.08658C20.1119 4.86144 19.1386 3.88807 17.9134 3.3806C17.6048 3.25277 17.1359 3.13486 16.173 3.06916C15.1832 3.00162 13.9048 3 12 3C10.0952 3 8.81682 3.00162 7.82697 3.06916C6.86406 3.13486 6.3952 3.25277 6.08658 3.3806C4.86144 3.88807 3.88807 4.86144 3.3806 6.08658C3.25277 6.3952 3.13486 6.86406 3.06916 7.82697C3.00162 8.81682 3 10.0952 3 12C3 13.9048 3.00162 15.1832 3.06916 16.173C3.13486 17.1359 3.25277 17.6048 3.3806 17.9134C3.88807 19.1386 4.86144 20.1119 6.08658 20.6194C6.3952 20.7472 6.86406 20.8651 7.82697 20.9308ZM0.608964 4.93853C0 6.4087 0 8.27247 0 12C0 15.7275 0 17.5913 0.608964 19.0615C1.42092 21.0217 2.97831 22.5791 4.93853 23.391C6.4087 24 8.27247 24 12 24C15.7275 24 17.5913 24 19.0615 23.391C21.0217 22.5791 22.5791 21.0217 23.391 19.0615C24 17.5913 24 15.7275 24 12C24 8.27247 24 6.4087 23.391 4.93853C22.5791 2.97831 21.0217 1.42092 19.0615 0.608964C17.5913 0 15.7275 0 12 0C8.27247 0 6.4087 0 4.93853 0.608964C2.97831 1.42092 1.42092 2.97831 0.608964 4.93853Z"
            fill="#151619"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3398 13.7642L5.60472 12.1859L6.39528 9.81415L11.1304 11.3925C11.6949 11.5807 12.3051 11.5807 12.8696 11.3925L17.6047 9.81415L18.3953 12.1859L13.6602 13.7642C12.5825 14.1234 11.4175 14.1234 10.3398 13.7642Z"
            fill="#151619"
          />
        </svg>
        {projectInfo?.name && (
          <span className={styles.projectName}>
            {titleCase(projectInfo.name)}
          </span>
        )}
      </div>
      <div className={classNames([styles.elements, styles.elementsAbsolute])}>
        <Button
          icon={IconChevronLeft}
          variant="ghost"
          onClick={onPrevLocalFn}
        />
        <Button
          label="Today"
          onClick={() => scrollTodayIntoView(false)}
          variant="ghost"
        />
        <Button
          icon={IconChevronRight}
          variant="ghost"
          onClick={onNextLocalFn}
        />
      </div>
      <div className={styles.elements}>
        <Button
          label={'Sign Up'}
          size="medium"
          link={{
            to: `${import.meta.env.VITE_FRONEND_URL}/sign-up`,
            target: '_blank',
          }}
          variant="outline"
        />
      </div>
    </div>
  );
}
