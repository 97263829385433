import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { updateResourcesOrder } from '@/services/api/workspace/resources';

type Props = {
  newOrder: string[];
};

export default function useReorderResources() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);

  return useMutation<void, Error, Props>({
    mutationFn: async ({ newOrder }: Props): Promise<void> => {
      await updateResourcesOrder({ workspaceId, newOrder });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
    },
    onError: (error: Error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
