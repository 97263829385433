import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import Button from '@components/Button';
import Input from '@components/Input';
import { validate as EmailValidator } from 'email-validator';
import { t } from 'i18next';
import { first } from 'lodash';
import { Controller, useForm } from 'react-hook-form';

import { UserContext } from '@/contexts/UserContext';
import useFirebaseDeleteFile from '@/hooks/firebase/useFirebaseDeleteFile';
import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import useAddNewResource from '@/hooks/workspace/resources/useAddNewResource';
import { titleCase } from '@/services/helpers';
import {
  STORAGE_BASE_PATH,
  WORKSPACE_MEMBER_PERMISSION,
  WORKSPACE_MODE,
} from '@/types/enums';
import { TAttachmentDataUrls } from '@/types/generic';
import { TUserMember } from '@/types/workspace';

import Modal from '@/components/Modals/Modal';

import styles from './styles.module.css';
import AvatarUploader from '../../AvatarUploader';
import SelectDropdown from '../../SelectDropdown';
import Switch from '../../Switch';

type Props = {
  isOpen: boolean;
  onboarding?: boolean;
  defaultName?: string;
  onClose: (newMember?: TUserMember | object, saved?: boolean) => void;
};

export default function ModalAddNewTeamMember({
  isOpen,
  onClose,
  onboarding = false,
  defaultName,
}: Props) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TUserMember>({ mode: 'onSubmit' });

  const { mutate, isPending } = useAddNewResource();

  useEffect(() => {
    if (defaultName) {
      const nm = defaultName.trim();
      if (!nm) return;
      const spaceIdx = nm.indexOf(' ');
      if (spaceIdx === -1) {
        setValue('firstName', titleCase(nm) ?? '');
        return;
      }
      const firstName = nm.slice(0, spaceIdx);
      const lastName = nm.slice(spaceIdx + 1);
      setValue('firstName', titleCase(firstName) ?? '');
      setValue('lastName', titleCase(lastName) ?? '');
    }
  }, [defaultName, setValue]);

  const { workspaceId, workspace, modeMultiplier } = useContext(UserContext);
  const { trackEvent } = useAnalytics();

  const [attachments, setAttachments] = useState<TAttachmentDataUrls[]>([]);
  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const watchCapacity = watch('capacity');
  const watchWorkspaceAccess = watch('workspaceAccess.hasAccess');
  const watchWorkspaceAccessEmail = watch('workspaceAccess.email');

  const [workspaceMemberRole, setWorkspaceMemberRole] =
    useState<WORKSPACE_MEMBER_PERMISSION>(
      WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE,
    );

  const onUploadSuccessFn = useCallback(
    (data: TAttachmentDataUrls) => {
      setAttachments([data]);
    },

    [],
  );

  const onSuccessFn = useCallback(
    (newMember: TUserMember) => {
      const opt: { source?: string } = {};
      if (onboarding) opt.source = 'onboarding';

      trackEvent(ANALYTICS_EVENTS.PERSON_CREATED, workspaceId as string, opt);
      if (
        newMember?.workspaceAccess?.hasAccess &&
        newMember?.workspaceAccess?.email
      ) {
        trackEvent(ANALYTICS_EVENTS.PERSON_INVITED, workspaceId as string, opt);
      }
      onClose(newMember, true);
    },
    [onClose, onboarding, trackEvent, workspaceId],
  );

  const onSubmitFn = (newMember: TUserMember) => {
    const image = first(attachments);
    mutate(
      {
        ...newMember,
        workspaceAccess: {
          ...newMember?.workspaceAccess,
          permission: workspaceMemberRole,
        },
        image: { filePath: image?.ref ?? '' },
      },
      { onSuccess: () => onSuccessFn(newMember) },
    );
  };

  const shouldBeDisabled =
    !watchFirstName ||
    !watchLastName ||
    Number(watchCapacity) < 0 ||
    (watchWorkspaceAccess && !watchWorkspaceAccessEmail);

  const { mutate: mutateDelete } = useFirebaseDeleteFile();
  const image = first(attachments);
  const onDeleteFileFromStorageFn = useCallback(() => {
    mutateDelete(
      { refPath: image?.ref ?? '' },
      { onSuccess: () => setAttachments([]) },
    );
  }, [image, mutateDelete]);
  useEffect(() => {
    ref?.current?.focus();
  }, [isOpen]);

  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref?.current?.focus();
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalKey={`modal-add-new-team-member-${workspaceId}`}
      title={t('common:modals.addNewTeamMember.title')}
      footer={
        <>
          <Button
            label={t('common:modals.addNewTeamMember.primaryCtaLabel')}
            onClick={handleSubmit(onSubmitFn)}
            size="medium"
            disabled={shouldBeDisabled}
            isLoading={isPending}
          />
          <Button
            label={t('common:cancel')}
            onClick={onClose}
            size="medium"
            variant="ghost"
          />
        </>
      }
    >
      <div className={styles.inputWrapper}>
        <AvatarUploader
          onRemoveFileSuccess={onDeleteFileFromStorageFn}
          basePath={STORAGE_BASE_PATH.RESOURCES}
          onChange={setAttachments}
          resourceName={watchFirstName || watchLastName}
          onUploadSuccess={onUploadSuccessFn}
          label={t('common:modals.profilePicture')}
          multiple={false}
          data={attachments}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmitFn)}>
        <div className={styles.inputWrapper}>
          <Controller
            rules={{
              required: {
                value: true,
                message: t('forms:required', {
                  field: t('common:modals.firstName'),
                }),
              },
              maxLength: {
                value: 180,
                message: t('forms:maxLength', {
                  field: t('common:modals.firstName'),
                  count: 180,
                }),
              },
            }}
            control={control}
            name="firstName"
            render={({ field: { value, name, onChange, onBlur } }) => {
              return (
                <Input
                  required
                  ref={ref}
                  onBlur={onBlur}
                  label={t('common:modals.firstName')}
                  value={value}
                  placeholder={'E.g. John'}
                  onChange={onChange}
                  name={name}
                  error={errors?.firstName?.message as string}
                />
              );
            }}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Controller
            rules={{
              required: {
                value: true,
                message: t('forms:required', {
                  field: t('common:modals.lastName'),
                }),
              },
              maxLength: {
                value: 180,
                message: t('forms:maxLength', {
                  field: t('common:modals.lastName'),
                  count: 180,
                }),
              },
            }}
            control={control}
            name="lastName"
            render={({ field: { value, name, onChange, onBlur } }) => {
              return (
                <Input
                  required
                  onBlur={onBlur}
                  label={t('common:modals.lastName')}
                  value={value}
                  placeholder={'E.g. Doe'}
                  onChange={onChange}
                  name={name}
                  error={errors?.lastName?.message as string}
                />
              );
            }}
          />
        </div>
        <div className={styles.halfWrapper}>
          <Controller
            rules={{
              validate: (value: number) => {
                if (value < 0) {
                  return t('forms:required', {
                    field: t('common:modals.capacity'),
                  }) as string;
                }
                return true;
              },
            }}
            defaultValue={0}
            control={control}
            name="capacity"
            render={({
              field: { value, name, onChange, onBlur },
              fieldState: { error },
            }) => {
              const onChangeFn = (e: React.ChangeEvent<HTMLInputElement>) => {
                if (
                  !isNaN(Number(e.target.value)) &&
                  Number(e.target.value) <= 7 * (8 / modeMultiplier)
                ) {
                  onChange(Number(e.target.value));
                }
              };
              return (
                <Input
                  required
                  onBlur={onBlur}
                  label={t('common:modals.capacity')}
                  value={value < 0 ? '' : value?.toString()}
                  placeholder={`E.g ${
                    workspace?.mode === WORKSPACE_MODE.DAYS ? 5 : 40
                  }`}
                  onChange={onChangeFn}
                  name={name}
                  error={error?.message as string}
                />
              );
            }}
          />
          <p className={styles.modeText}>
            {t(`common:${workspace?.mode ?? 'DAYS'}PerWeek`)}
          </p>
        </div>
        <div className={styles.inputWrapper}>
          <Controller
            rules={{
              required: false,
            }}
            control={control}
            name="workspaceAccess.hasAccess"
            render={({ field: { value, name, onChange } }) => {
              return (
                <Switch
                  id={name}
                  label={t('common:modals.accessWorkspace')}
                  checked={value ?? false}
                  onChange={onChange}
                />
              );
            }}
          />
        </div>
        {watch('workspaceAccess.hasAccess') ? (
          <div className={styles.workspaceAccessWrapper}>
            <div className={styles.inputWrapper}>
              <Controller
                rules={{
                  required: {
                    value: watch('workspaceAccess.hasAccess') ?? false,
                    message: t('forms:signIn.email.required'),
                  },
                  validate: (value) => {
                    if (!EmailValidator(value ?? ''))
                      return t('forms:signIn.email.invalid') as string;
                    return true;
                  },
                }}
                control={control}
                name="workspaceAccess.email"
                render={({ field: { value, name, onChange, onBlur } }) => {
                  return (
                    <Input
                      required
                      placeholder={t('common:placeholderEmail')}
                      value={value ?? ''}
                      onChange={onChange}
                      name={name}
                      onBlur={onBlur}
                      error={errors?.workspaceAccess?.email?.message as string}
                    />
                  );
                }}
              />

              <p className={styles.inputMessage}>
                {t('common:modals.emailInvitationMessage')}
              </p>
            </div>

            <SelectDropdown
              className={styles.permissionDropdown}
              label={t(
                `common:modals.roles.${
                  workspaceMemberRole ===
                  WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE
                    ? 'editor'
                    : 'viewer'
                }.label`,
              )}
            >
              <SelectDropdown.Item
                label={t('common:modals.roles.editor.label')}
                subLabel={t('common:modals.roles.editor.description')}
                onSelect={() =>
                  setWorkspaceMemberRole(
                    WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE,
                  )
                }
                checked={
                  workspaceMemberRole ===
                  WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE
                }
              />
              <SelectDropdown.Item
                label={t('common:modals.roles.viewer.label')}
                subLabel={t('common:modals.roles.viewer.description')}
                onSelect={() =>
                  setWorkspaceMemberRole(WORKSPACE_MEMBER_PERMISSION.READ_ONLY)
                }
                checked={
                  workspaceMemberRole === WORKSPACE_MEMBER_PERMISSION.READ_ONLY
                }
              />
            </SelectDropdown>
          </div>
        ) : (
          false
        )}
        <input type="submit" hidden />
      </form>
    </Modal>
  );
}
