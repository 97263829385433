import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@/hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { updateResource } from '@/services/api/workspace/resources';
import { TWorkspaceAccess } from '@/types/workspace';

import { SEARCH_MANAGER_RESOURCES_KEY } from './useSearchManagerResourcesQuery';
import { SEARCH_RESOURCES_KEY } from './useSearchResourcesQuery';

type Props = {
  firstName: string;
  lastName: string;
  capacity: number;
  resourceId: string;
  workspaceAccess?: TWorkspaceAccess;
};

export default function useUpdateResource() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);

  return useMutation<void, Error, Props>({
    mutationFn: async ({
      firstName,
      lastName,
      capacity,
      resourceId,
      workspaceAccess,
    }: Props): Promise<void> => {
      await updateResource({
        workspaceId,
        firstName,
        lastName,
        capacity,
        resourceId,
        workspaceAccess,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [SEARCH_RESOURCES_KEY, workspaceId],
      });
      queryClient.invalidateQueries({
        queryKey: [SEARCH_MANAGER_RESOURCES_KEY],
      });
    },
    onError: (error: Error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
