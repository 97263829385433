import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { INSIGHT_PART_QUERY_KEY } from '@/hooks/insights/useInsightsPartQuery';
import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import { HIDDEN_PROJECTS_QUERY } from '@/hooks/workspace/projects/useHiddenProjectQuery';
import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { TIMELINE_RESOURCE_QUERY_KEY } from '@/hooks/workspace/resources/useTimelineResourceQuery';
import { hideProject } from '@/services/api/workspace/projects';

import { GET_HIDDEN_QUERY } from '../resources/useHiddenQuery';

type Props = {
  projectId: string;
  resourceId: string;
};

export default function useHideProject() {
  const { workspaceId } = useContext(UserContext);
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation<void, Error, Props>({
    mutationFn: async ({ resourceId, projectId }: Props): Promise<void> => {
      await hideProject({ resourceId, workspaceId, projectId });
    },
    onSuccess: () => {
      trackEvent(ANALYTICS_EVENTS.PROJECT_ARCHIVED, workspaceId as string);
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [TIMELINE_RESOURCE_QUERY_KEY],
      });
      queryClient.invalidateQueries({ queryKey: [HIDDEN_PROJECTS_QUERY] });
      queryClient.invalidateQueries({ queryKey: [INSIGHT_PART_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [GET_HIDDEN_QUERY] });
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
