import { useContext } from 'react';

import {
  QueryClient,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

import { UserContext } from '@/contexts/UserContext';
import { http } from '@/services/api';
import { TUserAccount } from '@/types/workspace';

import { RESOURCES_QUERY_KEY } from './resources/useResourcesQuery';
import { TIMELINE_RESOURCE_QUERY_KEY } from './resources/useTimelineResourceQuery';
import { ACCOUNT_QUERY_KEY } from '../account/useAccountQuery';
import { ANALYTICS_EVENTS, useAnalytics } from '../utils/useAnalytics';

const setWorkspaceAutoHide = (
  queryClient: QueryClient,
  autoHide: boolean,
  workspaceId: string,
) => {
  const meData = queryClient.getQueryData<TUserAccount>([ACCOUNT_QUERY_KEY]);
  const clonedData = cloneDeep(meData);
  const workspace = clonedData?.workspaces?.find((w) => w.id === workspaceId);
  if (!workspace) return;
  workspace.autoHide = autoHide;
  queryClient.setQueryData([ACCOUNT_QUERY_KEY], clonedData);
  return workspace;
};

export function useUpdateAutoHide() {
  const { workspaceId } = useContext(UserContext);
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();

  return useMutation<void, Error, boolean>({
    mutationFn: async (autoHide: boolean) => {
      await http.put(`/workspaces/${workspaceId}/auto-hide`, { autoHide });
    },
    onMutate: (autoHide) =>
      setWorkspaceAutoHide(queryClient, autoHide, workspaceId!),
    onSuccess: (_data, autoHide) => {
      trackEvent(
        autoHide
          ? ANALYTICS_EVENTS.AUTO_HIDE_PROJECT_TURNED_ON
          : ANALYTICS_EVENTS.AUTO_HIDE_PROJECT_TURNED_OFF,
        workspaceId!,
      );
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [TIMELINE_RESOURCE_QUERY_KEY],
      });
    },
    onError: (_error, autoHide) => {
      setWorkspaceAutoHide(queryClient, !autoHide, workspaceId!);
    },
  });
}
