import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { INSIGHT_PART_QUERY_KEY } from '@/hooks/insights/useInsightsPartQuery';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@/hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { SEARCH_PROJECTS_KEY } from '@/hooks/workspace/projects/useProjectsQuery';
import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { TIMELINE_RESOURCE_QUERY_KEY } from '@/hooks/workspace/resources/useTimelineResourceQuery';
import { deleteProject } from '@/services/api/workspace/projects';

import { USAGE_INFO_QUERY_KEY } from '../useUsageInfo';

type Props = {
  projectId: string;
};

export default function useDeleteProject() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation<void, Error, Props>({
    mutationFn: async ({ projectId }: Props) => {
      await deleteProject({ workspaceId, projectId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [SEARCH_PROJECTS_KEY] });
      queryClient.invalidateQueries({ queryKey: [INSIGHT_PART_QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TIMELINE_RESOURCE_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [USAGE_INFO_QUERY_KEY, workspaceId],
      });
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
