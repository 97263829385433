import { useCallback, useState } from 'react';

import * as Tooltip from '@radix-ui/react-tooltip';
import { IconCopy, IconLink } from '@tabler/icons-react';
import classNames from 'classnames';
import { t } from 'i18next';
import { noop } from 'lodash';
import { useCopyToClipboard } from 'react-use';

import useRevokeShareProject from '@/hooks/workspace/projects/mutationsFunctions/share-links/useRevokeShareProject';
import useShareProject from '@/hooks/workspace/projects/mutationsFunctions/share-links/useShareProject';
import { TSharedProjectInfo } from '@/types/timeline';

import Button from '@/components/Button';
import Input from '@/components/Input';
import Switch from '@/components/Switch';

import Modal, { ModalProps } from '../Modal';
import styles from './styles.module.css';
type Props = Pick<ModalProps, 'isOpen' | 'overflowShouldPreventClose'> & {
  onClose: (answer?: boolean) => void;
} & {
  projectId: string;
  shared?: TSharedProjectInfo;
  onShare?: (
    data: TSharedProjectInfo | null,
    projectId: string,
  ) => void | Promise<void>;
  onRevokeShare?: (projectId: string) => void | Promise<void>;
};

export default function ModalShareProject({
  isOpen,
  onClose,
  shared,
  projectId,
}: Props) {
  const modalKey = `share-project-${projectId}`;

  const { mutate: shareProject } = useShareProject();

  const { mutate: revokeShareProject } = useRevokeShareProject();
  const [_, copyLink] = useCopyToClipboard();

  const [tooltipOpened, setTooltipOpened] = useState<boolean>(false);

  const [switchChecked, setSwitchChecked] = useState<boolean>(!!shared?.id);

  const copyLinkToClipboard = useCallback(() => {
    if (shared?.url) {
      copyLink(shared.url);
      setTooltipOpened(true);
      setTimeout(() => setTooltipOpened(false), 700);
    }
  }, [copyLink, shared?.url]);

  const onSwitchChange = useCallback(
    (share: boolean) => {
      setSwitchChecked((prev) => !prev);
      if (share)
        shareProject({ projectId }, { onError: () => setSwitchChecked(false) });
      else if (shared?.id)
        revokeShareProject(
          { projectId, shareId: shared.id },
          { onError: () => setSwitchChecked(true) },
        );
    },
    [shareProject, projectId, revokeShareProject, shared?.id],
  );
  return (
    <Modal
      modalKey={modalKey}
      isOpen={isOpen}
      title={t('modals:shareProject.title')}
      onClose={() => onClose(false)}
      footer={
        <Button size="medium" label="Done" onClick={() => onClose(false)} />
      }
    >
      <div className={styles.modalContentWrapper}>
        <span className={styles.iconContainer}>
          <IconLink size={20} />
        </span>
        <div className={styles.textContainer}>
          <span className={styles.textTitle}>
            {t('modals:shareProject.header.title')}
          </span>
          <span className={styles.textDescription}>
            {t('modals:shareProject.header.description')}
          </span>
        </div>

        <Switch
          checked={switchChecked}
          onChange={onSwitchChange}
          id={'activate-public-access'}
        />
      </div>
      {switchChecked && shared?.id && (
        <>
          <div
            className={classNames(
              styles.modalContentWrapper,
              styles.middleContainer,
            )}
          >
            <Input name={'url'} value={shared.url} readOnly onChange={noop} />
            <Tooltip.Provider>
              <Tooltip.Root open={tooltipOpened} disableHoverableContent={true}>
                <Tooltip.Trigger asChild>
                  <Button
                    label={t('modals:shareProject.copy')}
                    variant="outline"
                    size="medium"
                    icon={IconCopy}
                    iconProps={{ size: 20 }}
                    onClick={copyLinkToClipboard}
                  />
                </Tooltip.Trigger>
                <Tooltip.Portal
                  container={document.getElementById('radix-tooltip-portal')}
                >
                  <Tooltip.Content
                    className={classNames('TooltipContent')}
                    sideOffset={5}
                  >
                    <span>{t('modals:shareProject.copied')}</span>
                    <Tooltip.Arrow className="TooltipArrow" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
          <span className={styles.inputSescription}>
            {t('modals:shareProject.description')}
          </span>
        </>
      )}
    </Modal>
  );
}
